import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuService } from '@nebular/theme';
import { RxjsService, SessionService } from '../../services';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loading: boolean = false

  userData : any;
  items = [
    { title: 'Profile' },
    { title: 'Logout' },
  ];

  userPictureOnly: boolean = false;
  constructor(private nbMenuService: NbMenuService,public rxjsServices: RxjsService,private sessionService: SessionService, private router: Router) {
    this.rxjsServices.getGlobalProgressBarProperty().subscribe(val => {
      this.loading = val
    })

  }

  ngOnInit(): void {
    this.userData = this.sessionService.getUserItem('userData')
    console.log(this.userData);
    this.nbMenuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'my-context-menu'),
      map(({ item: { title } }) => title),
    )
    .subscribe(title => console.log('it',title));

    // this.items = [
    //   {
    //     label: 'Users', icon: 'pi pi-user', routerLink: ['/users']
    //   },
    //   // {
    //   //   label: 'Delete', icon: 'pi pi-times', command: () => {
    //   //   }
    //   // },
    //   // { label: 'Angular.io', icon: 'pi pi-info', url: 'http://angular.io' },
    //   { separator: true },
    //   {
    //     label: 'Logout', icon: 'pi pi-cog', command: () => {this.logOut()
    //     }
    //   }
    // ];
  }
  logOut() {
    this.sessionService.removeAllItem();
    this.router.navigate(['/']);
  }
}
