<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<mat-dialog-content class="mat-typography-title">
    <h2 mat-dialog-title>Archive Name
    </h2>
    <mat-icon mat-dialog-close style="cursor: pointer">close</mat-icon>
</mat-dialog-content>
<form class="form-signin" [formGroup]='createFolderForm' (ngSubmit)="onSubmit()">


    <mat-dialog-content class="mat-typography">

        <div class="col-12" style="margin-top: 15px;">
            <mat-form-field appearance="outline">
                <mat-label>Archive File Name</mat-label>
                <input matInput placeholder="Archive File Name" formControlName="folderName">
            </mat-form-field>
        </div>

    </mat-dialog-content>
    <br />
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button class="start-btn " color="primary" mat-button cdkFocusInitial>OK</button>
    </mat-dialog-actions>

</form>