import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from 'src/app/shared/enums';
import { CrudService, RxjsService, SessionService, SnackbarService } from 'src/app/shared/services';
import { IApplicationResponse } from 'src/app/shared/utils';
import { TreeNode } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs';
import { RemoveSlashPipe } from '../../pipes/remove-slash.pipe';
import { environment } from 'src/environments/environment';
import { S3 } from 'aws-sdk';
import { FolderService } from 'src/app/services/folder.service';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-rename',
  templateUrl: './rename.component.html',
  styleUrls: ['./rename.component.scss']
})
export class RenameComponent implements OnInit {
  selectedFILE: any;
  loading: boolean = false;
  renameForm: FormGroup
  isInvalid: boolean = false;
  istype: any;
  constructor(private formBulder: FormBuilder, private rxjsService: RxjsService, private crudService: CrudService, private snackbarService: SnackbarService,
    private sessionService: SessionService, private fileService: FileService, private toastr: ToastrService, private folderService: FolderService, private router: Router, private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.renameForm = this.formBulder.group({
      folderName: ['', Validators.required]
    })
  }
  ngOnInit(): void {

    this.selectedFILE = this.data;
    this.istype = this.selectedFILE?.folder_id == undefined ? 'File' : 'Folder';
    this.renameForm.get('folderName')?.setValue(this.selectedFILE?.fileName)
    console.log('selected file', this.selectedFILE)
    // this.getS3Bucket(this.renameForm.get('filename')?.value,)
  }
  showSuccess(msg) {
    this.toastr.success('', msg);
  }
  showError(msg: any) {
    this.toastr.error('', msg);
  }
  onSubmit() {
    this.loading = true;
    console.log('sumbmit', this.renameForm)
    if (this.renameForm.invalid) {
      this.loading = false;
      return;
    }


    // if (this.data?.actionName=='Move') {
    //  filevale={"moveFiles":this.moveFolderForm.get('filename')?.value,'currentFilePath':'/'+fulcurentpath+'/','targetFilePath':'/'+formValue.targetFilePath+'/'}
    // } else {
    //  filevale={"copyFiles":this.moveFolderForm.get('filename')?.value,'currentFilePath':'/'+fulcurentpath+'/','targetFilePath':'/'+formValue.targetFilePath+'/'}
    // }
    if (this.istype == 'File') {
      console.log('file', this.selectedFILE);
      const fileName = this.selectedFILE.file_name;

      // Split the file name and extension using the last dot
      const lastDotIndex = fileName.lastIndexOf(".");
      const name = fileName.slice(0, lastDotIndex);
      const extension = fileName.slice(lastDotIndex + 1);
      if (name == this.renameForm.value.folderName) {
        this.showError('Try Change the Different Name')
        this.loading = false;
      }
      else {
        this.fileService.RenameFile(this.selectedFILE.file_id, this.renameForm.value.folderName + '.' + extension).
          subscribe((response: any) => {
            this.loading = false;
            if (response.success) {
              // this.snackbarService.openSnackbar(this.istype +' '+ 'Renamed'+ ' Successfully!', ResponseMessageTypes.SUCCESS)
              this.showSuccess('File Renamed Successfully');
              this.rxjsService.setGlobalReloadProperty(true)

              this.dialogRef.close();
            } else {
              this.showError('Error Renaming File')
              // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
            }
          }, error => {
            this.loading = false;
            this.showError(error.error);
            // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
          });
      }

    } else {
      console.log('folder', this.selectedFILE);
      if (this.selectedFILE.folder_name == this.renameForm.value.folderName) {
        this.showError('Try Change the Different Name')
        this.loading = false;
      } else {
        this.folderService.RenameFolder(this.selectedFILE.folder_id, this.renameForm.value.folderName).
          subscribe((response: any) => {
            this.loading = false;
            if (response.success) {
              // this.snackbarService.openSnackbar(this.istype +' '+ 'Renamed'+ ' Successfully!', ResponseMessageTypes.SUCCESS)
              this.showSuccess('Folder Renamed Successfully');
              this.rxjsService.setGlobalReloadProperty(true)

              this.dialogRef.close();
            } else {
              this.showError('Error Renaming Folder')
              // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
            }
          }, error => {
            this.loading = false;
            this.showError(error.error);
            // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
          });
      }


    }


    //  
  }

}
