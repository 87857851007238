<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<mat-dialog-content class="mat-typography-title">
    <h2 style="color: white" mat-dialog-title>Create Folder
    </h2>
    <mat-icon mat-dialog-close style="cursor: pointer">close</mat-icon>
</mat-dialog-content>
<form class="form-signin" [formGroup]='createFolderForm' (ngSubmit)="onSubmit()">


    <div class="col-12" style="margin-top: 15px;">
        <mat-form-field appearance="outline">
            <mat-label>Folder Name</mat-label>
            <input matInput placeholder="Folder Name" formControlName="folder_name">
        </mat-form-field>
        <h4 *ngIf="isInvalid" style="    color: tomato;
        font-weight: bold;
        margin-bottom: 0px;">*Invalid Folder Name</h4>
        <p *ngIf="isInvalid" style="color: tomato;margin-left: 1rem;font-size: 13px;">The Folder Name is Same us Current Folder Name.Plaease Use Different Folder Name</p>
    </div>
    <mat-dialog-content class="mat-typography">


    </mat-dialog-content>
    <br />
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button class="start-btn " color="primary" mat-button  cdkFocusInitial>Create Folder</button>
    </mat-dialog-actions>

</form>
