<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<mat-dialog-content class="mat-typography-title">
    <h2 mat-dialog-title>Delete {{isFolder}}
    </h2>
    <mat-icon mat-dialog-close style="cursor: pointer">close</mat-icon>
</mat-dialog-content>
<div class="form-signin">
    <mat-dialog-content class="mat-typography">
        <p>Are you sure you want to delete {{folderName ? folderName : 'this'}} ? </p>
    </mat-dialog-content>
    <br />
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button class="start-btn" mat-button color="primary" cdkFocusInitial (click)="onSubmit()">Move To Trash</button>
    </mat-dialog-actions>

</div>