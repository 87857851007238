<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<mat-dialog-content class="mat-typography-title">
    <h2 style="color: white" mat-dialog-title>Choose a Folder

    </h2>
    <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
</mat-dialog-content>
<!-- (onNodeExpand)="loadNode($event)" -->
<p>Select a folder in the folder tree below.</p>
<form class="form-signin" [formGroup]='moveFolderForm' (ngSubmit)="onSubmit()">
    <p-tree [value]="items" selectionMode="single" [(selection)]="selectedItem" (onNodeUnselect)="nodeUnselect($event)"
        (onNodeSelect)="onNodeSelect($event)"></p-tree>
    <div class="col-12" style="margin-top: 15px;">
        <mat-form-field class="example-full-width">
            <!-- <mat-label>Telephone</mat-label> -->
            <span>Path: &nbsp;</span>
            <input type="text" matInput formControlName="targetFilePath" [readonly]="true">
            <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
        </mat-form-field>
    </div>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button class="start-btn " color="primary" mat-button cdkFocusInitial>OK</button>
    </mat-dialog-actions>

</form>