import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-modal-share-folder',
  templateUrl: './modal-share-folder.component.html',
  styleUrls: ['./modal-share-folder.component.scss']
})
export class ModalShareFolderComponent implements OnInit {
  readlink: any;
  writelink: any;
  host: any = 'https://digimedix-733d7.web.app/share?'
  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
    if (this.data.folder_id != null) {
      this.readlink = this.host + 'foldertoken=' + this.data.folder_id + '&' + 'operation=read';
      this.writelink = this.host + 'foldertoken=' + this.data.folder_id + '&' + 'operation=write';
    } else {
      this.readlink = this.host + 'filetoken=' + this.data.file_id + '&' + 'operation=read';
      this.writelink = this.host + 'filetoken=' + this.data.file_id + '&' + 'operation=write';
    }
  }

}
