<mat-sidenav-container style="background-color: #edf1f7;" class="example-container main-wrapper">

  <mat-sidenav class="side-navbar" mode="side" opened>
    <app-side-bar></app-side-bar>
  </mat-sidenav>
  <!-- <nb-layout> -->
  <!-- <mat-sidenav-content style="background-color: #edf1f7;"> -->
    <mat-progress-bar mode="indeterminate"  *ngIf="loading"></mat-progress-bar>
    <mat-progress-bar mode="determinate" value="{{value}}" *ngIf="fileLoading"></mat-progress-bar>
    <!-- <nb-layout-column> -->
    <router-outlet></router-outlet>
  <!-- </nb-layout-column>
  </nb-layout> -->
    <app-footer></app-footer>
  <!-- </mat-sidenav-content> -->

</mat-sidenav-container>
