import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SessionService } from '../shared/services';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  baseURL: string = environment.API_URL;

  constructor(private sessionService: SessionService, private http: HttpClient) {
    this.sessionService.getItem('token');
  }

  CreateFile(folder_id: any, files: any, filename: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    console.log(files);
    var blob = new Blob([new Uint8Array(files.data)], { type: 'application/zip' });

    const formData = new FormData();
    formData.append('files', blob, filename + '.zip');
    formData.append('folder_id', folder_id);
    formData.append('target_folder_id', folder_id);
    console.log(files, blob);

    return this.http.post<any>(this.baseURL + 'api/v4/createfile', formData, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  CreateFileUnique(folder_id: any, files: any, filename: any, type: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    console.log(files);
    var blob = new Blob([new Uint8Array(files)], { type: type });

    const formData = new FormData();
    formData.append('files', blob, filename);
    formData.append('folder_id', folder_id);
    formData.append('target_folder_id', folder_id);
    console.log(files, blob);

    return this.http.post<any>(this.baseURL + 'api/v4/createfile', formData, { headers, reportProgress: true, observe: 'events' })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  ArchiveFile(file_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    console.log(file_id);
    return this.http.put<any>(this.baseURL + 'api/v4/zipfile', { "file_id": file_id, "target_file_id": file_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  MoveFileToTrash(file_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v4/deletefile', { "file_id": file_id, "target_file_id": file_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  RenameFile(file_id: any, file_name: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v4/renamefile', { "file_id": file_id, "target_file_id": file_id, "new_file_name": file_name }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  MoveFile(file_id: any, folder_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v4/movefile', { "file_id": file_id, "target_folder_id": folder_id, "new_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  CopyFile(folder_id: any, file_name: any, file_key: any, size: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.post<any>(this.baseURL + 'api/v4/copyfile', { "target_folder_id": folder_id, "folder_id": folder_id, "file_name": file_name, "source_key": file_key, "size": size }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  RestoreFile(file_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v4/restorefile', { "file_id": file_id, "target_file_id": file_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }
  DeleteFileFromTrash(file_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v4/permanentdeletefromtrash', { "file_id": file_id, "target_file_id": file_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  GetFiledata(file_id: any): Observable<any> {
    return this.http.put<any>(this.baseURL + 'api/v4/getfileonly', { "file_id": file_id, "target_file_id": file_id, 'shareSecret': '1kWY9UYyopV3rp0iV3c7Ow==' })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  DownloadFile(file_key: any, file_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.post<any>(this.baseURL + 'api/v4/downloadfile', { "file_key": file_key, "file_id": file_id, "target_file_id": file_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }




}
