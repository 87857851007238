import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { FolderService } from 'src/app/services/folder.service';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from 'src/app/shared/enums';
import { CrudService, RxjsService, SessionService, SnackbarService } from 'src/app/shared/services';
import { IApplicationResponse } from 'src/app/shared/utils';

@Component({
  selector: 'app-modal-create-folder',
  templateUrl: './modal-create-folder.component.html',
  styleUrls: ['./modal-create-folder.component.scss']
})
export class ModalCreateFolderComponent implements OnInit {
  loading: boolean = false;
  createFolderForm: FormGroup;
  isInvalid: boolean = false;
  constructor(private formBulder: FormBuilder, private rxjsService: RxjsService, private crudService: CrudService, private snackbarService: SnackbarService,
    private sessionService: SessionService, private router: Router, private dialogRef: MatDialogRef<any>, private toastr: ToastrService, private folderService: FolderService) {
    this.createFolderForm = this.formBulder.group({
      folder_name: ['', Validators.required],
      parent_id: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    //let userData = this.sessionService.getUserItem('userData')
    //let requestParams = new HttpParams().set('baseDirectory', '/' + userData?.directory_info[0]?.baseDirectory + '/');

    //     this.crudService.getTreeTable(ModulesBasedApiSuffix.GETTREEVIEWTABLE,requestParams)
    //       .pipe(map((res: any) => {
    //         console.log('res  1',res)
    //         res?.data?.forEach((element: any) => {

    //         });

    //         return res;
    //       }))
    //       .subscribe((response: any) => {
    //          //let path='/bucketname/new folder/mp3'.split('/').filter(x=>x!='');
    //           var newarr:any=[];
    // //          response?.data.forEach((element:any) => {
    // //           newarr.id=element.id
    // //           newarr.path=JSON.parse(element.path);
    // // console.log('element.path',element)
    // //          });
    // response?.data.map((x:any)=>{
    // x.path=JSON.parse(x.path)
    // })
    // let path='/First/soft'.split('/').filter(x=>x!='');
    // console.log('split last path',path[path?.length-1])
    //       console.log('getdirectory',response?.data)

    //       let parentonly=response?.data.filter((x:any)=>x.path.every((e:any) => e.Parent=='0' && e.label==path[0]));

    //       console.log('parentonly find',parentonly);
    //       }, error => {
    //       });



    // let path='/ul2hfi5vem3b6qw/First'.split('/').filter(x=>x!='');
    // console.log('split',path)
    // let oldDBpath:any=[{ label:'ul2hfi5vem3b6qw', HIERARCHY_LEVEL: 1 ,Parent:'0'},{ Value: "First", HIERARCHY_LEVEL: 2 ,Parent:path[0]},
    // { Value: "second", HIERARCHY_LEVEL: 3 ,Parent:"ul2hfi5vem3b6qw"}];
    // if (path?.length==1)
    //  {
    // //parent
    //   let prcessdta={ label: path[0], HIERARCHY_LEVEL: 1 ,Parent:'0',expandedIcon: "pi pi-folder-open",collapsedIcon: "pi pi-folder"};
    //   oldDBpath.push(prcessdta);
    //   console.log("olddb parent",oldDBpath)
    // }else if (path?.length>1)
    // {
    //   //child
    //   let prcessdta={ label: path[path?.length-1], HIERARCHY_LEVEL: path?.length ,Parent:path[0],expandedIcon: "pi pi-folder-open",collapsedIcon: "pi pi-folder"};
    //   oldDBpath.push(prcessdta);
    //   console.log("olddb child",oldDBpath)

    //   let parentonly=oldDBpath.filter((x:any)=>x.Parent=='0');
    //   console.log('parent only',parentonly)
    //   let fresh:any= [];
    //   //join parent with child
    //   parentonly.forEach((p:any)=>{
    //     fresh.push(p);
    //     oldDBpath.forEach((c:any) => {
    //       if (c.Parent == p.label) {
    //        fresh.push(c)
    //       }
    //     });
    //   });
    //   console.log('parent with child',fresh)
    //   var x=this.getTree(fresh);
    //   console.log('gettree', x[0]);

    // }
  }
  showSuccess(msg) {
    this.toastr.success('', msg);
  }

  showError(msg: any) {
    this.toastr.error('', msg);
  }
  onSubmit() {
    let pathid = this.sessionService.getItem('curentFullPathArray') ? this.sessionService.getItem('curentFullPathArray') : 0;
    if (pathid == this.sessionService.getItem('homeID') && this.createFolderForm.value.folder_name.toString().toLowerCase() == 'root' && this.createFolderForm.value.folder_name.toString().toLowerCase() == 'home') {
      this.showError('Cannot able to create root folders');
    } else {
      this.createFolderForm.get('parent_id')?.setValue(pathid);
      console.log('create foldder', this.createFolderForm.value)
      this.folderService.CreateNewFolder(this.createFolderForm.value.parent_id, this.createFolderForm.value.folder_name)
        .subscribe((response) => {
          this.loading = false;
          if (response.success) {
            // this.snackbarService.openSnackbar('Folder Created Successfully!', ResponseMessageTypes.SUCCESS)
            this.showSuccess('Folder Created Successfully!');
            this.rxjsService.setGlobalReloadProperty(true)
            this.dialogRef.close();
          } else {
            this.showError(response.msg);
            // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
          }
        }, error => {
          this.loading = false;
          console.log('error', error)
          this.snackbarService.openSnackbar(error?.error, ResponseMessageTypes.WARNING)
        });
    }
  }


  onSubmit_old() {
    this.loading = true;
    let userData = this.sessionService.getUserItem('userData')
    let curentpath: any;
    let val: any = '';
    if (this.sessionService.getItem('curentFullPath')) {
      curentpath = curentpath = +'/';
      val = this.sessionService.getItem('curentFullPath')?.toString().slice(0, -1);
      curentpath = curentpath + val ? val : '';
    } else {
      curentpath = '';
    }

    this.createFolderForm.get('currentpath')?.setValue('/' + userData?.directory_info[0]?.baseDirectory + '/' + curentpath);
    console.log('sur', this.createFolderForm.value, 'bas', userData?.directory_info[0]?.baseDirectory)
    if (this.createFolderForm.get('currentpath')?.value.charAt(this.createFolderForm.get('currentpath')?.value.length - 1) == '/' && userData?.user?.role_id != 2) {
      this.createFolderForm.get('currentpath')?.setValue(this.createFolderForm.get('currentpath')?.value?.toString().slice(0, -1))
    }
    if (this.createFolderForm.invalid) {
      this.loading = false;
      return;
    }
    if (this.createFolderForm.get('folderName')?.value == this.sessionService.getItem('currentFolderName')?.toString().slice(0, -1)) {
      this.isInvalid = true;
      this.loading = false;
      return;
    } else {
      this.isInvalid = false;
    }
    //let formValue = this.createFolderForm.value;
    //formValue.currentpath = formValue.currentpath + this.sessionService.getItem('curentFullPath')?.toString().slice(0, -1);
    var path: any;
    path = this.sessionService.getItem('curentFullPath')?.toString().slice(0, -1) + '/' + this.createFolderForm.get('folderName')?.value;

    //return;
    console.log('given path', path);
    console.log('submit', this.createFolderForm.value)
    //   return;
    //     let userData = this.sessionService.getUserItem('userData')
    let requestParams = new HttpParams().set('baseDirectory', '/' + userData?.directory_info[0]?.baseDirectory + '/');
    this.folderService.CreateNewFolder(this.createFolderForm.value.parent_id, this.createFolderForm.value.folder_name)
      .subscribe((response) => {
        this.loading = false;
        if (response) {
          let activity: any; activity = { type: 'Folder Created', location: this.createFolderForm.get('currentpath')?.value + '/' + this.createFolderForm.get('folderName')?.value }; this.crudService.setTrack(activity);

          this.snackbarService.openSnackbar('Folder Created Successfully!', ResponseMessageTypes.SUCCESS)
          this.rxjsService.setGlobalReloadProperty(true)
          this.dialogRef.close();
        } else {
          // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
        }
      }, error => {
        this.loading = false;
        console.log('error', error)
        this.snackbarService.openSnackbar(error?.error?.message, ResponseMessageTypes.WARNING)
      });

    //  this.crudService.getTreeTable(ModulesBasedApiSuffix.GETTREEVIEWTABLE,requestParams)
    //    .pipe(map((res: any) => {
    //      console.log('res  1',res)
    //      return res;
    //    }))
    //    .subscribe((response: any) => {
    //       //let path='/bucketname/new folder/mp3'.split('/').filter(x=>x!='');
    //       path=path?.split('/').filter((x:any)=>x!='');
    //       console.log('tojson current path',path)
    //      console.log('split',path)
    //      let oldDBpath:any=[];
    //      response?.data.map((x:any)=>{
    //       x.path=JSON.parse(x.path)
    //       })
    //       oldDBpath.push(response?.data);
    //       console.log('olddbpath',oldDBpath);
    //    //  let oldDBpath:any=[{ label: path[0], HIERARCHY_LEVEL: 1 ,Parent:'0'},{ Value: "new foolder", HIERARCHY_LEVEL: 2 ,Parent:path[0]},
    //     // { Value: "video", HIERARCHY_LEVEL: 2 ,Parent:"movies"},{ Value: "movies", HIERARCHY_LEVEL: 1 ,Parent:"0"}
    //      //];
    //      // if (path.length==1) {
    //      //   let prcessdta={ label: path[0], HIERARCHY_LEVEL: 1 ,Parent:'0',expandedIcon: "pi pi-folder-open",collapsedIcon: "pi pi-folder"};
    //      // }
    //      // else
    //       if (path?.length==1)
    //       {
    //         if (!oldDBpath) {
    //           oldDBpath=[];
    //         }
    //      //parent
    //        let prcessdta={ label: path[0], HIERARCHY_LEVEL: 1 ,Parent:'0',expandedIcon: "pi pi-folder-open",collapsedIcon: "pi pi-folder"};
    //        oldDBpath.push(prcessdta);
    //        console.log("olddb parent",oldDBpath)
    //        this.createFolderForm.get('procJson')?.setValue(JSON.stringify(oldDBpath));
    //      }else if (path?.length>5) //>1
    //      {
    //        //child
    //        let newDBpath:any=[];
    //        let prcessdta={ label: path[path?.length-1], HIERARCHY_LEVEL: path?.length ,Parent:path[1],expandedIcon: "pi pi-folder-open",collapsedIcon: "pi pi-folder"};
    //        newDBpath.push(prcessdta);
    //        console.log("newDBpath child",newDBpath)

    //        //let parentonly=oldDBpath.filter((x:any)=>x.Parent=='0' && x.label==path[path?.length-1]);
    //     let parentonly=response?.data.filter((x:any)=>x.path.every((e:any) => e.Parent=='0' && e.label==path[0]));

    //        console.log('parent only',parentonly)
    //        let fresh:any= [];
    //        //join parent with child
    //        parentonly.forEach((p:any)=>{
    //          fresh.push(p.path);
    //          newDBpath.forEach((c:any) => {
    //            if (c.Parent == p.label) {
    //             fresh.push(c)
    //            }
    //          });
    //        });
    //        console.log('parent with child',fresh)
    //       let jsonTree=this.getTree(fresh);
    //        console.log('gettree', this.getTree(fresh));
    //        this.createFolderForm.get('procJson')?.setValue(JSON.parse(jsonTree));
    //        console.log('formvalur', this.createFolderForm.get('currentpath')?.value)

    //        console.log('ProcJSON', jsonTree);
    //      }
    //      console.log('create folder form',this.createFolderForm.value);


    //    }, error => {
    //    });

  }
  getTree(array: any) {
    var levels: any = [{}];
    array.forEach((o: any) => {
      levels.length = o.HIERARCHY_LEVEL;
      levels[o.HIERARCHY_LEVEL - 1].children = levels[o.HIERARCHY_LEVEL - 1].children || [];
      levels[o.HIERARCHY_LEVEL - 1].children.push(o);
      levels[o.HIERARCHY_LEVEL] = o;
    });
    return levels[0].children;
  }
}
