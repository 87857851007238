import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from 'src/app/shared/enums';
import { CrudService, RxjsService, SessionService, SnackbarService } from 'src/app/shared/services';
import { IApplicationResponse } from 'src/app/shared/utils';
import { TreeNode } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs';
import { RemoveSlashPipe } from '../../pipes/remove-slash.pipe';
import { environment } from 'src/environments/environment';
import { S3 } from 'aws-sdk';
import { FolderService } from 'src/app/services/folder.service';
import { ToastrService } from 'ngx-toastr';
import { error } from 'console';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-modal-move-folder',
  templateUrl: './modal-move-folder.component.html',
  styleUrls: ['./modal-move-folder.component.scss'],
  providers: [RemoveSlashPipe]
})
export class ModalMoveFolderComponent implements OnInit {

  items: TreeNode[] = []
  selectedItem!: TreeNode;
  loading: boolean = false
  moveFolderForm: FormGroup
  actionName: any;
  selectedFILE: any;
  isFolder: any;
  isMulti: boolean = false;
  constructor(private formBulder: FormBuilder, private removeSlashPipe: RemoveSlashPipe, private rxjsServices: RxjsService, private rxjsService: RxjsService, private crudService: CrudService, private snackbarService: SnackbarService,
    private sessionService: SessionService, private fileService: FileService, private router: Router, private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private folderService: FolderService, private toastr: ToastrService) {
    this.moveFolderForm = this.formBulder.group({
      folder_id: ['', Validators.required],
      targetFilePath: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.actionName = this.data ? this.data?.actionName : this.data?.actionName;
    this.selectedFILE = this.data?.data;

    if (this.selectedFILE?.length > 0) {
      this.selectedFILE[0].folder_id == undefined ? 'File' : 'Folder';
      this.isMulti = true;
    } else {
      this.selectedFILE[0].folder_id == undefined ? 'File' : 'Folder';
      this.isMulti = false;
    }
    console.log('seleted file', this.selectedFILE);
    //   let filename:any;var filetype:any;
    //   console.log('this.filename',this.data)
    this.gettreeview_new()
  }
  showSuccess(msg) {
    this.toastr.success('', msg);
  }
  showError(msg: any) {
    this.toastr.error('', msg);
  }
  gettreeview_new() {
    let folderid = this.sessionService.getItem('homeID');
    console.log('getree', folderid)

    this.folderService.GetFolderDataAll(folderid)
      .pipe(map((res: any) => {
        console.log('res  1', res)
        // res?.data?.children.forEach((element: any) => {
        //   element.type = element.type;
        //   element.isFolder = element.folder_name.includes('.') ? false : true;
        //   element.name = element.folder_name;
        //   element.id = element.folder_id;
        //   //   element.filePath =  userData?.directory_info[0]?.baseDirectory + '/' + element.Key
        //   //element.fileName = this.removeSlashPipe.transform(element.Key)

        // });
        res.data.children = res.data.children.filter((e: any) => { if (e.folder_id != this.selectedFILE[0].folder_id) { return e } else { return null } });
        console.log(res);
        return res
      }))
      .subscribe((response: any) => {
        response?.data.children.forEach((element: any) => {
          console.log(element);
          // this.items.push({ label: element.folder_name.toString(), folder_id: element.folder_id, expandedIcon: "pi pi-folder-open", collapsedIcon: "pi pi-folder", children: [{ label: "hello", folder_id: element.folder_id, expandedIcon: "pi pi-folder-open", collapsedIcon: "pi pi-folder" }] });
          this.items.push(element);
          console.log('restree', this.items)

        });
        var dat = {
          label: 'root:/', expandedIcon: "pi pi-folder-open", collapsedIcon: "pi pi-folder",
          folder_id: this.sessionService.getItem('homeID')
        };
        this.items.push(dat);
        this.items.reverse();
      },
        //


        error => {
          this.loading = false;
        });
  }
  onSubmit() {
    this.loading = true;
    console.log('submit', this.moveFolderForm.value)
    let filevale;
    if (this.moveFolderForm.invalid) {
      this.loading = false;
      return;
    }
    console.log(this.actionName);
    if (this.actionName == 'Move') {
      this.selectedFILE.forEach(element => {
        if (element.folder_id == this.moveFolderForm.value.folder_id) {
          this.showError('Cannot Move folder / file Iteratively');
          this.loading = false;
        }
        else {
          console.log(this.isFolder);
          if (element.folder_id != null) {
            this.folderService.MoveFolder(element.folder_id, this.moveFolderForm.value.folder_id)
              .subscribe((response: any) => {
                if (response.success) {
                  this.showSuccess('Folder Moved Successfully!');
                  this.loading = false;
                  this.rxjsService.setGlobalReloadProperty(true);

                  this.dialogRef.close();
                } else {
                  this.showError('Error: Folder Not Moved')
                }
              }, (err) => {
                this.showError(err.error);
              });
          }
          else {
            this.fileService.MoveFile(element.file_id, this.moveFolderForm.value.folder_id)
              .subscribe((response: any) => {
                if (response.success) {
                  this.showSuccess('File Moved Successfully!');
                  this.loading = false;
                  this.rxjsService.setGlobalReloadProperty(true);

                  this.dialogRef.close();
                } else {
                  this.showError('Error: File Not Moved')
                }
              }, (err) => {
                this.showError(err.error);
              });
          }
        }
      });
    }
    else {
      if (this.actionName == 'Copy') {
        this.selectedFILE.forEach(element => {
          if (element.folder_id == this.moveFolderForm.value.folder_id) {
            this.showError('Cannot Copy folder Iteratively');
            this.loading = false;
          }
          else {
            if (element.folder_id != null) {
              this.folderService.CopyFolder(element.folder_id, this.moveFolderForm.value.folder_id)
                .subscribe((response: any) => {
                  if (response.success) {
                    this.showSuccess('Folder Copied Successfully!');
                    this.loading = false;
                    this.rxjsService.setGlobalReloadProperty(true);
                    this.dialogRef.close();
                  } else {
                    this.showError('Error: Folder Not Copied')
                  }
                }, (err) => {
                  this.showError(err.error);
                });
            } else {
              this.fileService.CopyFile(this.moveFolderForm.value.folder_id, element.file_name, element.file_key, element.file_size)
                .subscribe((response: any) => {
                  if (response.success) {
                    this.showSuccess('File Copied Successfully!');
                    this.loading = false;
                    this.rxjsService.setGlobalReloadProperty(true);
                    this.dialogRef.close();
                  } else {
                    this.showError('Error: File Not Copied')
                  }
                }, (err) => {
                  this.showError(err.error);
                });
              console.log(element);
            }
          }
        });

      }
    }




    // this.crudService.create('', filevale).
    //   subscribe((response: any) => {
    //     this.loading = false;
    //     if (response.status == 200) {
    //       this.snackbarService.openSnackbar(this.isFolder + ' ' + this.actionName + ' Successfully!', ResponseMessageTypes.SUCCESS)

    //       this.rxjsService.setGlobalReloadProperty(true)
    //       this.dialogRef.close();
    //     } else {
    //       this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
    //     }
    //   }, error => {
    //     this.loading = false;
    //     this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
    //   });
  }
  onNodeSelect(event: any) {

    var itm = { label: "Tesfda" };
    if (event.node) {




      this.moveFolderForm.get('targetFilePath')?.setValue(event?.node?.label)
      this.moveFolderForm.get('folder_id')?.setValue(event?.node?.folder_id)


      console.log('event', event.node)
    }

  }
  ngOnInit1(): void {
    this.actionName = this.data ? this.data?.actionName : 'Move';
    //   let filename:any;var filetype:any;
    //   console.log('this.filename',this.data)


    //   if (this.data?.data?.length>1) {

    //     let arrr:any=[];
    //     this.data?.data?.forEach((element:any) => {

    //       if (element.fileName.includes('.')) {
    //         filename='';
    //         filetype='File'
    //       }else{
    //         filename='/';filetype='Folder'
    //       }

    //       arrr.push({'filename':element.fileName+filename});
    //     });
    //     console.log('multiple arrr',arrr)
    //     this.moveFolderForm.get('filename')?.setValue(arrr);
    //     console.log('filename value', this.moveFolderForm.get('filename')?.value);
    //     this.moveFolderForm.get('currentFilePath')?.setValue(this.data?.data[0]?.filePath)
    //   } else {
    //     if (this.data?.data?.fileName.includes('.')) {
    //       filename='';
    //       filetype='File'
    //     }else{
    //       filename='/';filetype='Folder'
    //     }
    //     this.moveFolderForm.get('filename')?.setValue([{'filename':this.data?.data?.fileName+filename}]);
    //     console.log('this.data?.data?.fileName',this.data?.data?.fileName);
    //     console.log('filename',filename);
    //     console.log('single filename value', this.moveFolderForm.get('filename')?.value);
    //   this.moveFolderForm.get('currentFilePath')?.setValue(this.data?.data?.filePath)

    //   }
    //   console.log('data',this.data)
    // this.getDashboardData();
    // this.items = [
    //   // {
    //   //   "label": "Documents",
    //   //   "data": "Documents Folder",
    //   //   "expandedIcon": "pi pi-folder-open",
    //   //   "collapsedIcon": "pi pi-folder",
    //   //   "children": [{
    //   //     "label": "Work",
    //   //     "data": "Work Folder",
    //   //     "expandedIcon": "pi pi-folder-open",
    //   //     "collapsedIcon": "pi pi-folder",
    //   //     "children": [{ "label": "Expenses.doc", "icon": "pi pi-file", "data": "Expenses Document" }, { "label": "Resume.doc", "icon": "pi pi-file", "data": "Resume Document" }]
    //   //   },
    //   //   {
    //   //     "label": "Home",
    //   //     "data": "Home Folder",
    //   //     "expandedIcon": "pi pi-folder-open",
    //   //     "collapsedIcon": "pi pi-folder",
    //   //     "children": [{ "label": "Invoices.txt", "icon": "pi pi-file", "data": "Invoices for this month" }]
    //   //   }]
    //   // },
    //   // {
    //   //   "label": "Pictures",
    //   //   "data": "Pictures Folder",
    //   //   "expandedIcon": "pi pi-folder-open",
    //   //   "collapsedIcon": "pi pi-folder",
    //   //   "children": [
    //   //     { "label": "barcelona.jpg", "icon": "pi pi-image", "data": "Barcelona Photo" },
    //   //     { "label": "logo.jpg", "icon": "pi pi-file", "data": "PrimeFaces Logo" },
    //   //     { "label": "primeui.png", "icon": "pi pi-image", "data": "PrimeUI Logo" }]
    //   // },
    //   // {
    //   //   "label": "Movies",
    //   //   "data": "Movies Folder",
    //   //   "expandedIcon": "pi pi-folder-open",
    //   //   "collapsedIcon": "pi pi-folder",
    //   //   "children": [{
    //   //     "label": "Al Pacino",
    //   //     "data": "Pacino Movies",
    //   //     "children": [{ "label": "Scarface", "icon": "pi pi-video", "data": "Scarface Movie" }, { "label": "Serpico", "icon": "pi pi-file-video", "data": "Serpico Movie" }]
    //   //   },
    //   //   {
    //   //     "label": "Robert De Niro",
    //   //     "data": "De Niro Movies",
    //   //     "children": [{ "label": "Goodfellas", "icon": "pi pi-video", "data": "Goodfellas Movie" }, { "label": "Untouchables", "icon": "pi pi-video", "data": "Untouchables Movie" }]
    //   //   }]
    //   // }
    // ]

  }

  // getDashboardData() {
  //   this.loading = true
  //   let userData = this.sessionService.getUserItem('userData')
  //   let requestParams = new HttpParams().set('baseDirectory', '/' + userData?.directory_info[0]?.baseDirectory + '/');
  //   this.crudService.get(ModulesBasedApiSuffix.GET_DASHBOARD, null, requestParams)
  //   .pipe(map((res: any) => {
  //     console.log('res',res)
  //     res?.forEach((element: any) => {

  //       element.type = element.Key.includes('.') ? 'file' : 'folder'
  //       element.isFolder = element.Key.includes('.') ? false : true
  //       element.filePath =  userData?.directory_info[0]?.baseDirectory +'/' +element.Key
  //       element.fileName =  this.removeSlashPipe.transform(element.Key)
  //       if (element.Key.includes('.')) {
  //         element.extention = element.Key.split('.')[element.Key.split('.').length - 1]
  //         if (element.extention == 'png' || element.extention == 'jpg') {
  //           element.extention = 'img'
  //         } else if (element.extention == 'mp3') {
  //           element.extention = 'mp3'
  //         } else if (element.extention == 'mp4') {
  //           element.extention = 'mp4'
  //         } else if (element.extention == 'txt') {
  //           element.extention = 'txt'
  //         } else if (element.extention == 'word') {
  //           element.extention = 'word'
  //         } else if (element.extention == 'excel') {
  //           element.extention = 'excel'
  //         } else {
  //           element.extention = 'doc'
  //         }
  //       } else {
  //         element.extention = null
  //       }
  //     });
  //     res?.data.forEach((element: any) => {
  //       element.label = element.Key,
  //         element.data = element.Key,
  //         element.expandedIcon = "pi pi-folder-open",
  //         element.collapsedIcon = "pi pi-folder"
  //       element.children = []
  //     })
  //     this.items = res
  //     console.log('items',this.items,'res',res)
  //     return res
  //   }))
  //   .subscribe((response: any) => {
  //       this.loading = false
  //       // response?.data.forEach((element: any) => {
  //       //   element.label = element.Key,
  //       //     element.data = element.Key,
  //       //     element.expandedIcon = "pi pi-folder-open",
  //       //     element.collapsedIcon = "pi pi-folder"
  //       //   element.children = []
  //       // })
  //       // this.items = response
  //       // console.log('items',this.items,'res',response)
  //     }, error => {
  //       this.loading = false
  //     });
  // }

  getDashboardData() {
    this.rxjsServices.setGlobalProgressBarProperty(true)
    let userData = this.sessionService.getUserItem('userData');
    let getbase = userData?.user?.role_id != '2' ? userData?.directory_info[0]?.baseDirectory : userData?.directory_info[0]?.bucket_name;
    console.log('userdata', getbase)
    let requestParams = new HttpParams().set('baseDirectory', '/' + userData?.directory_info[0]?.baseDirectory + '/');
    console.log('requestParams', requestParams)
    this.crudService.get(ModulesBasedApiSuffix.GET_DASHBOARD, null, requestParams)
      .pipe(map((res: any) => {
        console.log('res  1', res)
        res?.data?.children.forEach((element: any) => {
          element.type = element.Key.includes('.') ? 'file' : 'folder'
          element.isFolder = element.Key.includes('.') ? false : true
          element.filePath = getbase + '/' + element.Key
          element.fileName = this.removeSlashPipe.transform(element.Key)
          //element.fileName =element.Key.substring(1,element.Key-1);

          if (element.Key.includes('.')) {
            //element=[];

          } else {
            let curentpath = element.Key.split('/');
            console.log('curent path -2', curentpath[curentpath.length - 2])
            element.Key = curentpath[curentpath.length - 2];
            element.extention = null
          }
        });

        return res
      }))
      .subscribe((response: any) => {
        this.rxjsServices.setGlobalProgressBarProperty(false)
        //  this.dashBoardItems = response?.data;
        let baseFolder = this.sessionService.getUserItem('baseFolder');
        response?.data.forEach((element: any) => {
          element.label = element.Key,
            element.data = element.Key,
            element.expandedIcon = "pi pi-folder-open",
            element.collapsedIcon = "pi pi-folder"
          element.children = []
        })
        this.items = response?.data.children;
        this.items = response?.data.children.filter((val: any) => val.type !== 'file');
        console.log('baseFolder', baseFolder)
        if (baseFolder) {
          this.items = this.items.filter((val: any) => val.Key !== baseFolder.toString().slice(0, -1));
        }

        console.log('items', this.items, 'res', response)
      }, error => {
        this.rxjsServices.setGlobalProgressBarProperty(false)
      });
  }

  onNodeSelect1(event: any) {
    console.log(event)

    this.moveFolderForm.get('targetFilePath')?.setValue(event?.node?.label)
  }

  onSubmit1() {
    this.loading = true;
    if (this.moveFolderForm.invalid) {
      this.loading = false;
      return;
    }
    let userData = this.sessionService.getUserItem('userData');
    let getbase = userData?.user?.role_id != '2' ? userData?.directory_info[0]?.baseDirectory : userData?.directory_info[0]?.bucket_name;
    console.log('userdata', getbase)
    let formValue = this.moveFolderForm.value
    console.log('form value', formValue);
    formValue.targetFilePath = getbase + '/' + formValue.targetFilePath
    let curent = this.moveFolderForm.get('currentFilePath')?.value.split('/');
    let isMove = this.moveFolderForm.get('currentFilePath')?.value.split('/');
    let filevale;

    curent.pop();
    let fulcurentpath = curent.join("/");;
    console.log('fulcurentpath', fulcurentpath);

    if (this.data?.actionName == 'Move') {
      filevale = { "moveFiles": this.moveFolderForm.get('filename')?.value, 'currentFilePath': '/' + fulcurentpath + '/', 'targetFilePath': '/' + formValue.targetFilePath + '/' }
    } else {
      filevale = { "copyFiles": this.moveFolderForm.get('filename')?.value, 'currentFilePath': '/' + fulcurentpath + '/', 'targetFilePath': '/' + formValue.targetFilePath + '/' }
    }
    console.log('filevale', filevale)
    let crudService = this.actionName == 'Move' ? this.crudService.create(ModulesBasedApiSuffix.FOLDER_MOVE, filevale)
      : this.crudService.create(ModulesBasedApiSuffix.FOLDER_COPY, filevale)
    var filetype: any;
    if (this.moveFolderForm.get('filename')?.value.includes('.')) {

      filetype = 'File '
    } else {
      filetype = 'Folder '
    }
    crudService.subscribe((response: any) => {
      this.loading = false;
      if (response.status == '1' || response == '1') {
        this.snackbarService.openSnackbar(filetype + this.actionName + ' Successfully!', ResponseMessageTypes.SUCCESS)
        if (this.actionName == 'Move') {
          this.getS3Bucket(isMove, filetype)
          let activity: any; activity = { type: 'File Moved', location: isMove };
          this.crudService.setTrack(activity);
        } else {
          let activity: any; activity = { type: 'File Copied', location: isMove };
          this.crudService.setTrack(activity);
        }
        this.rxjsService.setGlobalReloadProperty(true)
        this.dialogRef.close();
      } else {
        this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
      }
    }, error => {
      this.loading = false;
      this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
    });
  }
  private getS3Bucket(path: any, isFolder: any): any {
    let userData = this.sessionService.getUserItem('userData');
    let er = false;
    path.shift();
    let filepath = path.join('/');
    let fileor = isFolder == 'Folder' ? 'Folder Deleted Successfully' : 'File Deleted Successfully';
    const bucket = new S3(
      {

        accessKeyId: environment.S3_ACCESS_KEY,
        secretAccessKey: environment.S3_SECRET_KEY,
        region: userData?.storage_Server?.Region,
      }
    );


    console.log('path+element.filename', filepath)
    var params = {
      Bucket: userData?.directory_info[0]?.bucket_name,
      Key: filepath
    }
    //path+element.filename
    console.log('params', params)

    bucket.deleteObject(params, function (err, data) {
      if (err) {
        console.log(err)
        er = true;
      }
      else {
        console.log("Successfully " + fileor, err, data);


      }
    });

    if (er) {

      this.snackbarService.openSnackbar('Error Something Went Wrong', ResponseMessageTypes.ERROR);
    } else {
      this.rxjsService.setGlobalReloadProperty(true);
      // this.snackbarService.openSnackbar(fileor, ResponseMessageTypes.SUCCESS)
    }
    this.dialogRef.close();

  }

}

