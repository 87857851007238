<nav class="navbar navbar-light" *ngIf="sessionService.getUserItem('userInfo')">
  <div class="container-fluid">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand">
        <img class="logo" style="width: 80%;margin-top: -1.2rem;"
        src="/assets/images/logo.jpeg">

      </a>
    </div>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

      <ul class="nav navbar-nav navbar-right">

        <li class="dropdown">

          <a style="text-decoration: none;margin-top: -1.1rem;" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            <img  style="width: 40px;" src="assets/images/user.png" alt="">
            {{sessionService.getUserItem('userInfo')?.first_name | uppercase}} <span class="caret"></span></a>
          <ul class="dropdown-menu">
            <!-- <li *ngFor="let item of items"><a href="#">{{item.title}}</a></li> -->
            <li *ngIf="sessionService.getUserItem('userInfo')?.role_id==2"><a style="text-decoration: none;" (click)="users()">Users</a></li>
            <li *ngIf="sessionService.getUserItem('userInfo')?.role_id==2" role="separator" class="divider"></li>
            <li><a style="text-decoration: none;" (click)="logOut()">Logout</a></li>
          </ul>
        </li>
      </ul>
    </div><!-- /.navbar-collapse -->
  </div><!-- /.container-fluid -->
</nav>
      <nb-layout>
        <nb-layout-header *ngIf="sessionService.getUserItem('userInfo')">
<!-- <div style="display: flex;justify-content: space-between;width: 100%;">          <div class="header-container">
            <div style="margin-left: 3rem;" class="logo-container">
              <img class="logo" style="width: 80%;"
              src="/assets/images/logo.jpeg">

            </div>

          </div>

          <div class="header-container">



                <nb-user size="large" name="{{sessionService.getUserItem('userInfo')?.first_name | uppercase}}"
                title="{{sessionService.getUserItem('userInfo')?.role_id==2?'Admin':'User'}}"
                [nbContextMenu]="items"
                nbContextMenuTag="my-context-menu"
                picture="assets/images/user.png"
                >
       </nb-user>
      </div>


          </div> -->

        </nb-layout-header>
        <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>
  </nb-layout>
