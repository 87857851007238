import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from 'src/app/shared/enums';
import { CrudService, RxjsService, SessionService, SnackbarService } from 'src/app/shared/services';
import { IApplicationResponse } from 'src/app/shared/utils';
import { TreeNode } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs';
import { RemoveSlashPipe } from '../../pipes/remove-slash.pipe';
import { environment } from 'src/environments/environment';
import { S3 } from 'aws-sdk';
import { FolderService } from 'src/app/services/folder.service';
import { ToastrService } from 'ngx-toastr';
import { error } from 'console';
import { FileService } from 'src/app/services/file.service';
@Component({
  selector: 'app-modal-trash-folder',
  templateUrl: './modal-trash-folder.component.html',
  styleUrls: ['./modal-trash-folder.component.scss']
})
export class ModalTrashFolderComponent implements OnInit {
  dashBoardItems: any = []
  items: TreeNode[] = []
  selectedItem!: TreeNode;
  loading: boolean = false;
  actionName: any;
  selectedFILE: any;
  isFolder: any;
  isMulti: boolean = false;
  selectedItems: any = [];
  constructor(private formBulder: FormBuilder, private rxjsServices: RxjsService, private rxjsService: RxjsService, private crudService: CrudService, private snackbarService: SnackbarService,
    private sessionService: SessionService, private fileService: FileService, private router: Router, private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private folderService: FolderService, private toastr: ToastrService) {

    var userinfo = this.sessionService.getUserItem('userInfo');
    console.log(this.sessionService.getUserItem('userInfo'))
    console.log('data', this.data);
    this.getData(userinfo.id);
  }

  ngOnInit() {

    //   let filename:any;var filetype:any;
    //   console.log('this.filename',this.data)
  }
  getData(data) {
    this.folderService.GetTrashFolderData(data)
      .subscribe((response: any) => {
        this.rxjsServices.setGlobalProgressBarProperty(false)
        console.log(response);
        this.dashBoardItems = response?.data.children;

        console.log('dash item', this.dashBoardItems);
      }, error => {
        this.rxjsServices.setGlobalProgressBarProperty(false)
      });
  }
  onSubmit() {



  }
  onSelected(item, event) {
    console.log(item, event);
    item.checked = event.checked;
    this.getSelectedItems()
  }

  getSelectedItems() {
    let items = this.dashBoardItems.filter((x: any) => x.checked == true)
    this.selectedItems = items
    console.log('select', this.selectedItems)
  }

  onNodeSelect(event) {

  }
  showSuccess(msg) {
    this.toastr.success('', msg);
  }

  showError(msg: any) {
    this.toastr.error('', msg);
  }
  onRestore() {
    this.selectedItems.forEach(element => {
      if (element.type == 'folder') {
        this.folderService.RestoreFolder(element.folder_id).subscribe((response: any) => {
          this.loading = false;
          if (response.success) {
            // this.snackbarService.openSnackbar(this.isFolder +' '+ this.actionName + ' Successfully!', ResponseMessageTypes.SUCCESS)
            this.showSuccess(response.msg);
            this.rxjsService.setGlobalReloadProperty(true)
            this.dialogRef.close();
          } else {
            this.showError('Error in Restoring Folder');
            // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
          }
        }, error => {
          this.loading = false;
          this.showError(error.error);
          // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
        });
      } else {
        this.fileService.RestoreFile(element.file_id).subscribe((response: any) => {
          this.loading = false;
          if (response.success) {
            // this.snackbarService.openSnackbar(this.isFolder +' '+ this.actionName + ' Successfully!', ResponseMessageTypes.SUCCESS)
            this.showSuccess(response.msg);
            this.rxjsService.setGlobalReloadProperty(true)
            this.dialogRef.close();
          } else {
            this.showError('Error in Restoring File');
            // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
          }
        }, error => {
          this.loading = false;
          this.showError(error.error);
          // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
        });
      }
    });
  }
  onDelete() {
    this.selectedItems.forEach(element => {
      if (element.type == 'folder') {
        this.folderService.DeleteFolderFromTrash(element.folder_id).subscribe((response: any) => {
          this.loading = false;
          if (response.success) {
            // this.snackbarService.openSnackbar(this.isFolder +' '+ this.actionName + ' Successfully!', ResponseMessageTypes.SUCCESS)
            this.showSuccess(response.msg);
            this.rxjsService.setGlobalReloadProperty(true)
            this.dialogRef.close();
          } else {
            this.showError('Error in Deleting Folder');
            // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
          }
        }, error => {
          this.loading = false;
          this.showError(error.error);
          // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
        });
      } else {
        this.fileService.DeleteFileFromTrash(element.file_id).subscribe((response: any) => {
          this.loading = false;
          if (response.success) {
            // this.snackbarService.openSnackbar(this.isFolder +' '+ this.actionName + ' Successfully!', ResponseMessageTypes.SUCCESS)
            this.showSuccess(response.msg);
            this.rxjsService.setGlobalReloadProperty(true)
            this.dialogRef.close();
          } else {
            this.showError('Error in Deleting File');
            // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
          }
        }, error => {
          this.loading = false;
          this.showError(error.error);
          // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
        });
      }
    });
  }

}
