<div class="container">
  <div class="row">
    <div class="col-md-6 col-md-offset-3" style="margin-top: 5%">
      <form class="form-signin" [formGroup]='loginForm' (ngSubmit)="onSubmit()">
        <div style="text-align: center;margin-bottom: 25px;">
          <img src="/assets/images/logo.jpeg">

        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <mat-card>
          <mat-card-title class="mat-headline">
            <h1 style="text-align: center;font-size: 40px;color: black;">Log in</h1>
          </mat-card-title>
          <div class="col-12" style="margin-top: 15px;font-size: 15px;">
            <mat-form-field appearance="outline">
              <mat-label>
                User Name
              </mat-label>
              <input matInput placeholder="User Name" formControlName="email">
              <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
              <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>
          </div>
          <div class="col-12" style="margin-top: 15px;font-size: 15px;">
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput placeholder="Password" formControlName="password" type="password">
              <mat-error *ngIf="loginForm.get('password')?.hasError('minlength')">
                Confirm Password Must be at least 8 characters long
              </mat-error>
              <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
              <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>
          </div>
          <div class="col-12" style="margin-top: 20px;font-size: 15px;">
            <button mat-raised-button color="primary" style="width: 100%;font-size: 15px;" type="submit">{{loading ?
              'Loging in...' :
              'Log in'}}</button>
          </div>
          <div class="col-12" style="margin-top: 20px;text-align: center;font-size: 15px;">
            <p style="font-size: 15px;">Don't have a account? <a routerLink="/sign-up">Sign Up</a> </p>
            <a routerLink="/forgot-password">Forgot your password?</a>
            <!-- <a routerLink="/sign-up">Sign Up</a> -->
          </div>
        </mat-card>
      </form>
    </div>


  </div>
</div>