import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FolderActionType } from '../enums';
import { GetTreeViewComponent } from '../modules/get-tree-view/get-tree-view.component';
import { ModalArchiveFolderComponent } from '../modules/modal-archive-folder/modal-archive-folder.component';
import { ModalCreateFolderComponent } from '../modules/modal-create-folder/modal-create-folder.component';
import { ModalDeleteFolderComponent } from '../modules/modal-delete-folder/modal-delete-folder.component';
import { ModalInfoFolderComponent } from '../modules/modal-info-folder/modal-info-folder.component';
import { ModalMoveFolderComponent } from '../modules/modal-move-folder/modal-move-folder.component';
import { ModalSendFolderComponent } from '../modules/modal-send-folder/modal-send-folder.component';
import { ModalShareFolderComponent } from '../modules/modal-share-folder/modal-share-folder.component';
import { ModalUploadComponent } from '../modules/modal-upload/modal-upload.component';
import { RenameComponent } from '../modules/rename/rename.component';
import { ModalTrashFolderModule } from '../modules/modal-trash-folder/modal-trash-folder.module';
import { ModalTrashFolderComponent } from '../modules/modal-trash-folder/modal-trash-folder.component';
import { LoginComponent } from 'src/app/components/login/login.component';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  constructor(public dialog: MatDialog) { }

  openUploadDialog() {
    const dialogRef = this.dialog.open(ModalUploadComponent, {
      width: '70%',
      hasBackdrop: false,
      closeOnNavigation: false,
      backdropClass: 'backdropBackground'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openCreateFolderDialog() {
    const dialogRef = this.dialog.open(ModalCreateFolderComponent, {
      width: '30%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }

  openDeleteFolderDialog(data: any, actionType?: FolderActionType | any) {

    let type = {
      actionName: actionType
    }
    let datas = { ...type, data }
    const dialogRef = this.dialog.open(ModalDeleteFolderComponent, {
      data: datas,
      width: '20%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }

  openInfoFolderDialog(data: any) {
    const dialogRef = this.dialog.open(ModalInfoFolderComponent, {
      data: data,
      width: '40%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }

  openArchiveFolderDialog(data: any, actionType?: any) {
    let type = {
      actionName: actionType
    }
    let datas = { ...type, data }
    const dialogRef = this.dialog.open(ModalArchiveFolderComponent, {
      data: datas,
      width: '30%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }
  openRenameFolderDialog(data: any) {

    const dialogRef = this.dialog.open(RenameComponent, {
      data: data,
      width: '30%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }
  openMoveFolderDialog(data: any, actionType?: FolderActionType | any) {
    console.log(data);
    let type = {
      actionName: actionType
    }
    let datas = { ...type, data }
    const dialogRef = this.dialog.open(ModalMoveFolderComponent, {
      data: datas,
      width: '50%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }

  openSendFolderDialog(data: any) {
    const dialogRef = this.dialog.open(ModalSendFolderComponent, {
      data: data,
      width: '40%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }
  opengetTreeviewDialog(data: any) {
    const dialogRef = this.dialog.open(GetTreeViewComponent, {
      data: data,
      width: '40%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }

  openShareFolderDialog(data: any) {
    const dialogRef = this.dialog.open(ModalShareFolderComponent, {
      data: data,
      width: '80%',
      // height: '80%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }
  openTrashFolderDialog(data: any) {
    console.log(data);
    const dialogRef = this.dialog.open(ModalTrashFolderComponent, {
      data: data,
      width: '80%',
      // height: '80%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }

  openLoginDialog(data: any) {
    console.log(data);
    const dialogRef = this.dialog.open(LoginComponent, {
      data: data,
      width: '60%',
      // height: '80%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.getDashboardData()
    });
  }

}
