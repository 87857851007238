<div class="sidebar-list">
  <ul>
    <li (click)="File()">
      <button mat-button>
        <mat-icon> file_copy</mat-icon>
        <span>File</span>
      </button>
    </li>
    <li>
      <button mat-button>
        <mat-icon>send</mat-icon>
        <span>Share</span>
      </button>
    </li>
    <li routerLink="/activity">
      <button mat-button>
        <mat-icon>timeline</mat-icon>
        <span>Activity</span>
      </button>
    </li>
    <li routerLink="/notification">
      <button mat-button>
        <mat-icon>access_time</mat-icon>
        <span>Notification</span>
      </button>
    </li>
  </ul>

  <ul class="bottom-list">
    <li (click)="modelService.openTrashFolderDialog(homeID)">
      <button mat-button>
        <mat-icon> delete</mat-icon>
        <span>Trash</span>
      </button>
    </li>
    <li routerLink="/settings">
      <button mat-button>
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </button>
    </li>
    <li>
      <button mat-button>
        <mat-icon>help</mat-icon>
        <span>Help</span>
      </button>
    </li>
    <li (click)="logOut()">
      <button mat-button>
        <mat-icon>exit_to_app</mat-icon>
        <span>Log Out</span>
      </button>
    </li>
  </ul>
</div>