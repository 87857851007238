

<!-- <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar> -->

<mat-dialog-content class="mat-typography-title">
    <h2 mat-dialog-title>Send 
    </h2>
    <mat-icon mat-dialog-close style="cursor: pointer">close</mat-icon>
</mat-dialog-content>
<form class="form-signin" >


    <mat-dialog-content class="mat-typography">

        <app-common-share></app-common-share>

    </mat-dialog-content>
    <br />
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button class="start-btn " color="primary" mat-button  cdkFocusInitial>Save</button>
    </mat-dialog-actions>

</form>