import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-send-folder',
  templateUrl: './modal-send-folder.component.html',
  styleUrls: ['./modal-send-folder.component.scss']
})
export class ModalSendFolderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
