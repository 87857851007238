import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSlash'
})
export class RemoveSlashPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let valueNew = value.substring(value.lastIndexOf("/") + 1);
    if (valueNew) {
      return valueNew;
    } else {
      value = value.replace(/\//g, '');
      return value
    }
  }

}
