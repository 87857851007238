import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { S3 } from 'aws-sdk';
import { ModelService } from 'src/app/shared/services/model.service';

import { S3UploadService } from 'src/app/shared/services/s3-upload.service';
import { environment } from 'src/environments/environment';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from '../../enums';
import { CrudService, RxjsService, SessionService, SnackbarService } from '../../services';
import { IApplicationResponse } from '../../utils';
import { FileService } from 'src/app/services/file.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.scss']
})
export class ModalUploadComponent implements OnInit {
  fileList: File[] = [];
  listOfFiles: any[] = [];
  isUpload: boolean = false;
  values: number = 0;
  fileSize: any;
  storageServerList: any;
  constructor(private crudService: CrudService, private toastr: ToastrService, private fileService: FileService, public dialogRef: MatDialogRef<ModalUploadComponent>, private s3UploadService: S3UploadService, private rxjsService: RxjsService, public modelService: ModelService, private httpClient: HttpClient, private sessionService: SessionService, private snackbarService: SnackbarService) {
    this.rxjsService.getGlobalFileUploadIndexProperty().subscribe(val => {
      if (val || val == 0) {
        if (this.listOfFiles.length >= 0) {
          this.listOfFiles[val].done = true
        }
      }
    })
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {

  }

  async upload() {
    this.isUpload = true;
    // const file = this.selectedFiles.item(0);
    // this.s3UploadService.uploadFile(file);
    const file = this.listOfFiles;
    console.log(' this.fileList;', this.fileList)
    for (let i = 0; i < file.length; i++) {
      // console.log(i,file.item(i))
      await this.uploadFile(file[i], i)

    }
  }



  niceBytes(x: any) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }
  showSuccess(msg) {
    this.toastr.success('', msg);
  }
  showError(msg: any) {
    this.toastr.error('', msg);
  }
  async uploadFile(file: any, index?: any) {

    this.rxjsService.setGlobalProgressBarUploadProperty(true)
    this.values = 0;

    let pathid = this.sessionService.getItem('curentFullPathArray') ? this.sessionService.getItem('curentFullPathArray') : 0;
    console.log('file =', file, pathid);
    this.fileService.CreateFileUnique(pathid, file.buffer, file.name, file.type).subscribe((event) => {
      if (event.type === 0) {
        // Upload started
      } else if (event.type === 1) {
        // Upload progress
        const progress = Math.round((event.loaded / event.total) * 100);
        console.log(`Upload progress: ${progress}%`);
        this.rxjsService.setGlobalProgressBarUploadProgressProperty(progress);
        this.values = progress - 2;

      } else if (event.type === 4) {
        // Upload completed
        console.log('File uploaded successfully.');
        this.values = 100;
        this.showSuccess('File Uploaded Successfully');
        this.rxjsService.setGlobalProgressBarUploadProperty(false);

        if (index == this.fileList.length - 1) {
          // this.rxjsService.setGlobalReloadProperty(true)
          this.dialogRef.close();
        }
        this.rxjsService.setGlobalReloadProperty(true)
        return;
      }

    }, error => {
      this.rxjsService.setGlobalProgressBarUploadProperty(false);
      this.showError(error.error);
      // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
    })

  }
  selectFile(event?: any) {
    const selectedFiles: FileList = event.target.files;

    for (let i = 0; i < selectedFiles.length; i++) {
      const file: File = selectedFiles[i];
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        // e.target.result contains the ArrayBuffer of the file
        const fileBuffer: ArrayBuffer = e.target.result as ArrayBuffer;

        // Now you can use the fileBuffer as needed
        console.log(`Buffer of ${file.name}:`, fileBuffer);
        selectedFiles[i]['buffer'] = fileBuffer;
      };

      // Read the file as ArrayBuffer
      reader.readAsArrayBuffer(file);
      this.fileList.push(selectedFiles[i]);
      this.listOfFiles.push(selectedFiles[i]);
    }

    console.log('hello', this.fileList, this.listOfFiles);
  }

  removeSelectedFile(index: any) {
    this.fileList.splice(index, 1);
    this.listOfFiles.splice(index, 1);
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  // From drag and drop
  onDropSuccess(event: any) {
    event.preventDefault();
    console.log(event.dataTransfer.files)
    let dropData = {
      target: {
        files: event.dataTransfer.files
      }
    }
    this.selectFile(dropData);    // notice the "dataTransfer" used instead of "target"
  }
}
