<!-- <p>header works!</p> -->
<!-- <mat-progress-bar mode="indeterminate" *ngIf="rxjsServices.getGlobalProgressBarProperty()"></mat-progress-bar> -->
<!-- <div class="header-wrapper">
  <div class="row ">
    <div class="col-xs-9">
      <img class="logo"
        src="https://digimedix.exavault.com/content/view/logo/d8c9a1a53403fb19a0c0228d2cad0e44.jpeg?1656425557388">
    </div>
    <div class="col-xs-2 text-align-right" style="float: right;">
      <div class="user">
        <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png">

        <p-splitButton label="Welcome {{userData?.user?.firstname | uppercase}}" [model]="this.items" styleClass="p-button-text mr-2 mb-2"></p-splitButton>

      </div>
    </div>
  </div>
</div> -->
<div class="header-container">
  <div class="logo-container">

    <a class="logo" href="#" > <img class="logo"
      src="https://digimedix.exavault.com/content/view/logo/d8c9a1a53403fb19a0c0228d2cad0e44.jpeg?1656425557388"></a>
  </div>

</div>

<div class="header-container">
  <nb-actions size="small">


    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-user name="Nikita Poltoratsky"
             title="full-stack developer"
             [nbContextMenu]="items"
             nbContextMenuTag="my-context-menu">
    </nb-user>
  </nb-actions>
</div>
