import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';
import { RxjsService } from './rxjs.services';
import { SnackbarService } from './snackbar.service';
import { ResponseMessageTypes } from '../enums';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class S3UploadService {

  constructor(private httpClient: HttpClient, private sessionService: SessionService, private rxjsService: RxjsService, private snackbarService: SnackbarService) { }

  private getS3Bucket(): any {
    let userData = this.sessionService.getUserItem('userData')
    const bucket = new S3(
      {
        accessKeyId: environment.S3_ACCESS_KEY,
        secretAccessKey: environment.S3_SECRET_KEY,
        region: userData?.storage_Server?.Region,
        httpOptions: {timeout: 0},
        // region: 'ca-central-1',
        // region: 'us-east-1',
       // maxRetries: 3,
      }
    );

    return bucket;
  }
  getdirectory(){
    let userData = this.sessionService.getUserItem('userData');

    const bucket = new S3(
      {
        accessKeyId: environment.S3_ACCESS_KEY,
        secretAccessKey: environment.S3_SECRET_KEY,
        region: userData?.storage_Server?.Region,
        httpOptions: {timeout: 0},
        // region: 'ca-central-1',
        // region: 'us-east-1',
       // maxRetries: 3,
      }
    );
    var params = {
      Bucket: userData?.directory_info[0]?.bucket_name,
      Delimiter: '/',
      Prefix: '/VijayF2'
     }
     let Data;
     bucket.listObjects(params, function (err, data) {
      if(err)throw err;
      console.log('list of dir',data);
      Data=data;
     });
     return Data;
  }

  uploadFile(file: any, index?: any) {

    this.rxjsService.setGlobalProgressBarUploadProperty(true)
    let userData = this.sessionService.getUserItem('userData')
    let folderName = this.sessionService.getItem('currentFolderName')
    const contentType = file.type;

    const params = {
      Bucket: userData?.directory_info[0]?.bucket_name,
      Key: (folderName ? folderName : '') + file.name,
      Body: file,
      ContentType: contentType,
     // partSize: 6 * 1024 * 1024,
   //   queueSize: 1,
      ACL: 'public-read',
      // Expires: 3600
    };
console.log(params)

    // bucket.getSignedUrl("putObject", params, (error, url) => {
    //   if (error) console.log("error", error);
    //   if (url) {
    //     this.httpClient.put(url, file).subscribe((response) => {
    //       console.log("response receved is ", response);
    //     });
    //   }
    // });

    // bucket.upload(params, (err: any, data: any) => {
    //   if (err) {
    //     console.log('There was an error uploading your file: ', err);
    //     this.snackbarService.openSnackbar('There was an error uploading your file', ResponseMessageTypes.ERROR)
    //     this.rxjsService.setGlobalProgressBarUploadProperty(false)
    //     return false;
    //   }
    //   console.log('Successfully uploaded file.', data);

    //   this.snackbarService.openSnackbar('Successfully uploaded file', ResponseMessageTypes.SUCCESS)
    //   this.rxjsService.setGlobalProgressBarUploadProperty(false)
    //   return true;
    // });

    //for upload progress
    this.getS3Bucket().upload(params).on('httpUploadProgress', (evt:any) => {
      console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
      file.progress = (evt.loaded / evt.total) * 100;
      console.log(file.progress)
      this.rxjsService.setGlobalProgressBarUploadProgressProperty(file.progress)
    }).send((err: any, data: any) => {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        this.snackbarService.openSnackbar('There was an error uploading your file', ResponseMessageTypes.ERROR)
        this.rxjsService.setGlobalProgressBarUploadProperty(false)
        return false;
      }
      console.log('Successfully uploaded file.', data);

      this.snackbarService.openSnackbar('Successfully uploaded file', ResponseMessageTypes.SUCCESS)
      this.rxjsService.setGlobalProgressBarUploadProperty(false)
      this.rxjsService.setGlobalReloadProperty(true)
      this.rxjsService.setGlobalFileUploadIndexProperty(index)
      return true;
    });

  }



}
