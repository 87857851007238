import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { SideBarComponent } from './shared/components/side-bar/side-bar.component';
import { MaterialModule } from './shared/modules/material.module';
import { SuccessErrorHandlerInterceptor } from './shared/services';
import { SplitButtonModule } from 'primeng/splitbutton';
import { NbThemeModule, NbLayoutModule, NbButtonModule, NbActionsModule, NbUserModule, NbMenuModule, NbContextMenuModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NewDashboardComponent } from './components/new-dashboard/new-dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { ModelService } from './shared/services/model.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SideBarComponent,
    LayoutComponent,
    PageNotFoundComponent,
    NewDashboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SplitButtonModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbMenuModule.forRoot(),
    NbContextMenuModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right', // Position of toasts
      preventDuplicates: true,         // Prevent duplicate toasts
      progressBar: false,               // Show a progress bar
      closeButton: true,
    })

  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
