enum ResponseMessageTypes {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
    INFO = 'info'
}

enum KeyboardEvents {
    CUT = 'cut',
    COPY = 'copy',
    PASTE = 'paste',
    KEY_DOWN = 'keyDown',
    KEY_UP = 'keyUp',
    KEY_PRESS = 'keyPress'
}

enum FolderActionType {
    Move = 'Move',
    COPY = 'Copy',
}

enum FileActionType {
  Multiple = 'Multiple',
  Single = 'Single',
}

enum FileType {
    TXT = 'txt',
    JPG = 'JPG',
    PNG = 'png',
    DOC = 'doc',
    FOLDER = 'folder',
    WORD = 'word',
    EXCEL = 'excel',
}

enum RestfulMethods {
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    GET = 'GET'
}

enum ModulesBasedApiSuffix {
    LOGIN = "auth/login",
    GET_USER_CLIAMS = "api/user/getUserClaims",

    CREATE_FOLDER = "api/createfolder",
    //FOLDER_INFO = 'api/file/getinfo',
    FOLDER_DOWNLOAD = 'api/file/download',

    FOLDER_FILE_RENAME = 'api/file/file-rename',
    USER_CREATE = 'api/user/createUser',
    USER_UPDATE = 'api/user/update-user',
    USER_DELETE = 'api/user/delete-user',
    PERMISSION_GET_LIST = 'api/common/permissionList',
    SHARE_DIRECTORY = 'api/share/share-directory',
    GETTREEVIEW = 'api/file/getTreeView',
    GETTREEVIEWTABLE = 'api/file/gettreeviewtable',
    DELTE_USER = 'api/user/deleteuser',
    GET_USER = 'api/user/getUser',
    GETTRACK = "api/user/activitytrack",
//new
    GET_DASHBOARD = "filemanager/getfiles/",
    FOLDER_INFO = 'folder/getinfo/',
    FOLDER_CREATE = 'folder/',
    FILE_MOVE = 'file/move',
    FILE_COPY = 'file/copy',
    FOLDER_DELETE = 'file/delete',
    FOLDER_COPY='folder/copy',
    FOLDER_MOVE='folder/move',
    FOLDER_RENAME = 'folder/rename/',
    FILE_RENAME = 'file/rename/',
    DOWNLOAD='file/download',
    GET_STORAGE_SERVER_ID = 'server',
    SIGNUP = "auth/register",
    GET_USERS = 'user',
    UPLOAD = 'file/upload',


}
// enum ModulesBasedApiSuffix {
//   LOGIN = "public/api/login",
//   SIGNUP = "public/api/signup",
//   UPLOAD = "public/api/upload",
//   GET_USER_CLIAMS = "public/api/user/getUserClaims",
//   GET_DASHBOARD = "public/api/file/getDashboard",
//   CREATE_FOLDER = "public/api/createfolder",
//   GET_STORAGE_SERVER_ID = 'public/api/common/getstorageserverlist',
//   FOLDER_CREATE = 'public/api/file/createfolder',
//   FOLDER_DELETE = 'public/api/file/delete',
//   FOLDER_RENAME = 'public/api/file/folder-rename',
//   FOLDER_INFO = 'public/api/file/getinfo',
//   FOLDER_DOWNLOAD = 'public/api/file/download',
//   FOLDER_MOVE = 'public/api/file/move',
//   FOLDER_COPY = 'public/api/file/copy',
//   FOLDER_FILE_RENAME = 'public/api/file/file-rename',
//   GET_USERS = 'public/api/user/getUser',
//   USER_CREATE = 'public/api/user/createUser',
//   USER_UPDATE = 'public/api/user/update-user',
//   USER_DELETE = 'public/api/user/delete-user',
//   PERMISSION_GET_LIST = 'public/api/common/permissionList',
//   SHARE_DIRECTORY = 'public/api/share/share-directory',
//   GETTREEVIEW = 'public/api/file/getTreeView',
// }



export { ResponseMessageTypes, KeyboardEvents, RestfulMethods, ModulesBasedApiSuffix,FolderActionType }
