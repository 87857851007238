import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { error } from 'console';
import { SessionService } from '../shared/services';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  tokenKey = 'JWT';
  isAuthenticated: Boolean = false;
  username: Subject<string> = new Subject<string>();
  token: any;
  authToken: string | undefined;
  baseURL: string = environment.API_URL;

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  logIn(user: any): Observable<any> {
    return this.http.post<any>(this.baseURL + 'api/v1/users/login',
      { 'email': user.email, 'password': user.password })
      .pipe(map(res => {
        // this.username = user.username;
        // localStorage.setItem('username', user.username);
        return res;
      }),
        catchError(error => throwError(error)));
  }

  signUp(formData: any): Observable<any> {
    return this.http.post<any>(this.baseURL + 'api/v1/users/signup',
      { 'username': formData.firstname + ' ' + formData.lastname, 'email': formData.email, 'password': formData.password, 'mobile': formData.phone, 'role': 'admin', 'company': formData.company_name, 'region': formData.storage_server })
      .pipe(map(res => {
        // this.username = user.username;
        // localStorage.setItem('username', user.username);
        return res;
      }),
        catchError(error => throwError(error)));
  }

  CreateStorageServer(region: any, token: string): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    console.log(region);
    return this.http.post<any>(this.baseURL + 'api/v2/createbucket', { "region": region }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error)));
  }

  AssignStorageServer(region: any, token: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.post<any>(this.baseURL + 'api/v2/createbucket', { "storage_id": region }, { headers: headers }) // not verified
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error)));
  }

  GetUsers(): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    console.log(this.sessionService.getItem('authToken'));
    return this.http.put<any>(this.baseURL + 'api/v1/users/getusers', {}, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  CreateUser(formvalue: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.post<any>(this.baseURL + 'api/v1/users/createuser', { "first_name": formvalue.first_name, "last_name": formvalue.last_name, "email": formvalue.email, "password": formvalue.password, "phone": formvalue.phone }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

}
