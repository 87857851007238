import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IApplicationResponse } from "../utils";
import { SessionService } from "./session.service";
@Injectable({ providedIn: "root" })
export class CrudService {
  apiPrefixEnvironmentUrl: string = environment.API_URL;
  constructor(private httpClient: HttpClient, public sessionService: SessionService) { }


  create(url: any, body: object | Array<object>,
  ): Observable<IApplicationResponse> {
    return this.httpClient.post<any>(
      this.apiPrefixEnvironmentUrl + url,
      body
    );
  }
  Deletepost(url: any, body: object | Array<object>,
    ): Observable<IApplicationResponse> {
      return this.httpClient.post<any>(
        this.apiPrefixEnvironmentUrl + url,
        body
      );
    }
  createuser(url: any, body: object | Array<object>,
    ): Observable<IApplicationResponse> {
      return this.httpClient.post<any>(
        this.apiPrefixEnvironmentUrl + url,
        body
      );
    }
  fileUpload(url: any,
    body: object | Array<object>,
  ): Observable<IApplicationResponse> {
    return this.httpClient.post<any>(
      this.apiPrefixEnvironmentUrl,
      body
    );
  }
  //params?: HttpParams,
  get(url: any,
    retrievableId?: string | number | undefined | null,
    params?: any,
  ): Observable<IApplicationResponse> {
    if (retrievableId) {
      let finalParams: string;
      let URL: string;
      if (params) {
        // finalParams = params ? params.toString().replace('%20', ' ') : null;
        // finalParams = params ? params.toString().replace('%2520', ' ') : null;
        URL = this.apiPrefixEnvironmentUrl + "/" + url + retrievableId + "?" + params;
      }
      else {
        URL = this.apiPrefixEnvironmentUrl + "/" + url + retrievableId;
      }
      if (params) {
        return this.httpClient.get<IApplicationResponse>(
          URL
        );
      } else {
        return this.httpClient.get<IApplicationResponse>(
          this.apiPrefixEnvironmentUrl + "/" + url + retrievableId
        );
      }
    }
    else {
      let finalParams: string;
      let URL: string;
      if (params) {
        // finalParams = params ? params.toString().replace('%20', ' ') : null;
        // finalParams = params ? params.toString().replace('%2520', ' ') : null;
        //URL = this.apiPrefixEnvironmentUrl + url + "?" + params.toString();
        URL = this.apiPrefixEnvironmentUrl + url + params.toString();
      }
      else {
        URL = this.apiPrefixEnvironmentUrl + url;
      }
console.log('return url',URL)
      return this.httpClient.get<IApplicationResponse>(
        URL
      );

    }
  }
  getTreeTable(url: any,  params?: HttpParams
  ): Observable<IApplicationResponse> {

      let URL: string;
        URL = this.apiPrefixEnvironmentUrl + url + "?" + params?.toString();
console.log('return url',URL)
      return this.httpClient.get<IApplicationResponse>(
        URL
      );
  }


  update(url: any,
    body: object | Array<object>,
    apiVersion = 0
  ): Observable<IApplicationResponse> {
    return this.httpClient.put<IApplicationResponse>(
      this.apiPrefixEnvironmentUrl,
      body
    );
  }

  enableDisable(url: any,
    body: object | Array<object>,
    apiVersion: Number = 0

  ): Observable<IApplicationResponse> {
    return this.httpClient.put<IApplicationResponse>(
      this.apiPrefixEnvironmentUrl + '/enable-disable',
      body
    );
  }

  delete(url: any,
    body: any,
    apiVersion = 0
  ): Observable<IApplicationResponse> {

    return this.httpClient.get<IApplicationResponse>(
      this.apiPrefixEnvironmentUrl+url,
  {    params:body}
    );
  }
  // delete(url: any,
  //   selectedIds: string,
  //   params?: HttpParams
  //   //apiVersion = 0
  // ): Observable<IApplicationResponse> {

  //   return this.httpClient.delete<IApplicationResponse>(
  //     this.apiPrefixEnvironmentUrl+url,
  //     {
  //       params: selectedIds ? { ids: selectedIds } : params
  //     }
  //   );
  // }
  deleteByParams(url: any,
    body: object,
    apiVersion = 0
  ): Observable<IApplicationResponse> {

    return this.httpClient.delete<IApplicationResponse>(
      this.apiPrefixEnvironmentUrl+url,
      body
    );
  }
  // createuser(url: any, body: object | Array<object>,
  //   ): Observable<IApplicationResponse> {
  //     return this.httpClient.post<any>(
  //       this.apiPrefixEnvironmentUrl + url,
  //       body
  //     );
  //   }
  deleteFiles(url: any,
    body: object,
  ): Observable<IApplicationResponse> {

    return this.httpClient.delete<any>(
      this.apiPrefixEnvironmentUrl+url,
      body
    );
  }
  GetfileInfo(url: any,
    param,
  ): Observable<IApplicationResponse> {

    return this.httpClient.get<IApplicationResponse>(
      this.apiPrefixEnvironmentUrl + "/" + url + param
    );
  }
  deleteuser(url: any,id:any ,  ): Observable<IApplicationResponse>  {
      let URL: string;
        URL = this.apiPrefixEnvironmentUrl  + url +'/'+ id;
        return this.httpClient.get<IApplicationResponse>(
          URL
        );
  }

  downloadFile(url: any,
    body?: object,
    apiVersion = 0,
    params?: HttpParams | string,
  ): Observable<any> {
    if (params)
      return this.httpClient.get(this.apiPrefixEnvironmentUrl + params, {
        responseType: 'blob',
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream'
        })
      })
    else
      return this.httpClient.get(this.apiPrefixEnvironmentUrl, {
        responseType: 'blob',
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream'
        })
      })
  }
  setTrack(body:any)
  {
    let userData = this.sessionService.getUserItem('userData');
    let getbase=userData?.directory_info[0]?.bucket_name;
    let data=Object.assign(body,{bucket:getbase})
    this.setTrackPost('api/user/newactivity',data)
    .subscribe((response: IApplicationResponse) => {

      if (response.status == 1) {

      } else {
        console.log('error activity track',data)
      }
    }, error => {
      console.log('error activity track',data,error)
    });

  }
  setTrackPost(url: any, body: object | Array<object>,
    ): Observable<IApplicationResponse> {
      return this.httpClient.post<any>(
        this.apiPrefixEnvironmentUrl + url,
        body
      );
    }
    GetActivity(url: any,
      apiVersion = 0
    ): Observable<IApplicationResponse> {

      return this.httpClient.get<IApplicationResponse>(
        this.apiPrefixEnvironmentUrl+url,

      );
    }
}
