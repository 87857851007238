<div class="bckdrop"></div>
<mat-dialog-content class="mat-typography-title">
  <h2 mat-dialog-title>Upload Files
  </h2>
  <mat-icon mat-dialog-close style="cursor: pointer">close</mat-icon>
</mat-dialog-content>

<mat-dialog-content class="mat-typography file-dropper">

  <div class="row">
    <div class="col-lg-6">
      <div *ngIf="listOfFiles.length > 0" class="files-queue">
        <div class="queue files">
          <div class="file item" *ngFor="let item of listOfFiles; let i=index">
            <div class="top-right close">
              <span class="close-icon" *ngIf="!item?.done" (click)="removeSelectedFile(i)">×</span>
              <span class="completed-icon" *ngIf="item?.done">
                <mat-icon class="share-icon" style="color: green"> done</mat-icon>
              </span>
            </div>
            <div class="{{item?.extencen}}  " style="width: 20%;float: left;text-align: center;">

              <mat-icon class="share-icon" style="color: gray" *ngIf="item?.type.includes('image')"> image</mat-icon>
              <mat-icon class="share-icon" style="color: gray" *ngIf="item?.type.includes('application')"> folder</mat-icon>
              <mat-icon class="share-icon" style="color: gray" *ngIf="item?.type.includes('text')"> title</mat-icon>
              <!-- <mat-icon class="share-icon" style="color: gray"> {{item?.extencen}} </mat-icon> -->


              <span class="icon-overlay {{item?.extencen}}">
                {{item?.extencen}}
              </span>
            </div>
            <div class="meta-information pull-left">
              <div class="item-name">{{item?.name}}</div>
            </div>
            <div class="uploading" style="background-color: rgb(93, 174, 249); color: rgb(255, 255, 255);">
              <div class="indicator" style="width: 0%; background-color: rgb(27, 84, 235);">
                <div class="shine"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="information" style="color: rgb(255, 255, 255);"><span class="message">{{listOfFiles.length}} item to
            be uploaded</span>
          <span class="speed">{{fileSize}}</span>
          <div class="color-bar" style="background-color: rgb(93, 174, 249); color: rgb(255, 255, 255);">
            <div class="indicator" style="width: 0%; background-color: rgb(27, 84, 235); color: rgb(255, 255, 255);">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{'col-lg-6': listOfFiles.length > 0, 'col-lg-12' : listOfFiles.length == 0}">
      <div class="choose-file" (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
        <h5>Drag & Drop files or folders here</h5>
        <p>- or -</p>
        <section>
          <div class="example--row">
            <label class="file-btn custom-file-upload">
              <input type="file" multiple (change)="selectFile($event)" />
              Add Files
            </label>
            <!-- <button class="file-btn" mat-stroked-button>Add Files</button> -->
            <!-- <button class="file-btn" mat-stroked-button mat-dialog-close
              (click)="modelService.openCreateFolderDialog()">Add Folder</button> -->
          </div>
        </section>

      </div>
    </div>

  </div>

  <div *ngIf="isUpload" style="margin-top:2rem">
    <p-progressBar [value]="values"></p-progressBar>
<p style="text-align:center;">{{values!=100?'File Uploading... ':'Completed'}}</p>
  </div>

</mat-dialog-content>
<br />
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button class="start-btn" mat-button color="primary"  [disabled]="!listOfFiles"
    (click)="upload()">Start Upload</button>
</mat-dialog-actions>
