import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from 'src/app/shared/enums';
import { CrudService, SessionService, SnackbarService } from 'src/app/shared/services';
import { IApplicationResponse } from 'src/app/shared/utils';
import { AppComponent } from 'src/app/app.component'
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { FolderService } from 'src/app/services/folder.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading: boolean = false
  loginForm: FormGroup

  constructor(private appcomp: AppComponent, private formBuilder: FormBuilder, private crudService: CrudService, private sessionService: SessionService,
    private router: Router, private snackbarService: SnackbarService, private authService: AuthService, private toastr: ToastrService, private folderService: FolderService, @Inject(MAT_DIALOG_DATA) public data: any) {
    let host = window.location.toString();
    // email: [host.includes('localhost') ? 'vijay@test.com' : '', [Validators.required, Validators.email]],
    // password: [host.includes('localhost') ? '12345678' : '', [Validators.required]],
    this.loginForm = this.formBuilder.group({
      email: [host.includes('localhost') ? 'muthukumar@gmail.com' : '', [Validators.required, Validators.email]],
      password: [host.includes('localhost') ? 'admin123' : '', [Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(100)])]],
      //   grant_type: ['password', [Validators.required]]
    })
  }

  ngOnInit(): void {
  }
  showSuccess(msg) {
    this.toastr.success('', msg);
  }

  showError(msg: any) {
    this.toastr.error('', msg);
  }
  onSubmit(): void {
    this.loading = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    console.log(this.loginForm.value);
    this.authService.logIn({ email: this.loginForm.get('email')?.value, password: this.loginForm.get('password')?.value }).subscribe(
      (response) => {
        console.log(response);
        if (response.success) {
          // this.snackbarService.openSnackbar('Logged In Successfully!', ResponseMessageTypes.SUCCESS)

          this.showSuccess('Logged In Successfully!');
          if (response.result.storage_server == null) {
            this.authService.CreateStorageServer(response.result.region, response.token).subscribe((res) => {
              this.showSuccess('Storage Server Created');
              if (response.result.role == 'admin') {
                this.folderService.CreateRootFolder(response.token).subscribe((responsee) => {
                  this.SetUserData(response);
                  this.loading = false;
                }, (err1) => {

                })
              } else {
                this.loading = false;
                this.SetUserData(response);
              }
            }, (err) => {
              console.log(err);
              this.showError(err.error);
            });
          } else {
            // if (response.result.role == 'admin') {
            //   this.folderService.CreateRootFolder(response.token).subscribe((response) => {
            //     this.SetUserData(response);
            //   }, (err1) => {

            //   })
            // } else {
            //   this.SetUserData(response);
            // }
            this.loading = false;
            this.SetUserData(response);
          }

          // this.loading = false;
        } else {
          this.showError(response.msg);
          // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
          this.loading = false;
        }
      },
      (error) => {

      }
    );
  }
  SetUserData(response) {
    if (response) {
      this.sessionService.setUserItem('userInfo', response?.result);
      // this.sessionService.setUserItem('bucket_info', response?.bucket_info);
      this.sessionService.setItem('authToken', response.token);
      //  this.appcomp.menuItem();
      if (response?.result.role == 'admin') {
        this.folderService.GetHomeFolder(response?.result.id).subscribe((response) => {
          console.log(response);
          this.sessionService.setItem('homeID', response.result.folder_id);
          const queryParams = { id: response.result.folder_id, name: response.result.folder_name };
          this.router.navigate(['dashboard'], { queryParams });
        }, (error12) => {

        });
      } else {
        this.folderService.GetHomeFolder(response?.result.created_by).subscribe((response) => {
          console.log(response);
          this.sessionService.setItem('homeID', response.result.folder_id);
          const queryParams = { id: response.result.folder_id, name: response.result.folder_name };
          this.router.navigate(['dashboard'], { queryParams });
        }, (error12) => {

        });
      }

    }
  }
  getUserData() {
    this.loading = true;
    this.crudService.get(ModulesBasedApiSuffix.GET_USER_CLIAMS)
      .subscribe((response: any) => {
        this.loading = false;
        // if (response.status == 1) {
        var directory: any | undefined;
        if (response) {
          directory = response;
          console.log('directory.match(new RegExp("/"))', directory?.directory_info[0]?.baseDirectory.includes('/'));
          if (directory?.directory_info[0]?.baseDirectory.includes('/')) {
            let val = directory?.directory_info[0]?.baseDirectory.substring(1, directory?.directory_info[0]?.baseDirectory.length - 1);
            console.log('split base direc', val);
            directory?.directory_info.map((x: any) => {
              x.baseDirectory = val;
            })
            let basefolder = val.split('/');
            this.sessionService.setUserItem('baseFolder', basefolder[1] + '/');
            console.log('basefolder', basefolder);
          } else {
            this.sessionService.setUserItem('baseFolder', '');
          }
          console.log('after base', directory)
          this.sessionService.setUserItem('userData', directory);
          let activity: any; activity = { type: 'Login', location: '/' };
          this.crudService.setTrack(activity);
          this.appcomp.menuItem();
          this.router.navigate(['dashboard'])
          // }
        }
      }, error => {
        this.loading = false;
      });
  }


}
