<!-- <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar> -->

<mat-dialog-content class="mat-typography-title">
    <h2 mat-dialog-title>Sharing
    </h2>
    <mat-icon mat-dialog-close style="cursor: pointer">close</mat-icon>
</mat-dialog-content>
<form class="form-signin">


    <mat-dialog-content class="mat-typography">

        <!-- <app-common-share></app-common-share> -->
        <form class="example-form ">
            <div class="share-form">
                <div class="add-cc">
                    <p>Copy the link and share with your friends</p>
                    <!-- <button mat-button>Add CC</button> -->
                    <h3>Link for ReadAccess</h3>
                    <h4>{{readlink}}</h4>
                    <br>
                    <h3>Link for WriteAccess</h3>
                    <h4>{{writelink}}</h4>
                </div>
                <!-- <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Add Someone">
                </mat-form-field> -->
            </div>
            <!-- <div class="share-form">
                <p>Also CC these email addresses</p>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Password (optional)">
                </mat-form-field>
            </div>
            <div class="share-form">
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Add Someone">
                </mat-form-field>
            </div> -->
        </form>

    </mat-dialog-content>
    <br />
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button class="start-btn " color="primary" mat-button mat-dialog-close>Save</button>
    </mat-dialog-actions>

</form>