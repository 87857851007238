import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { CrudService, SessionService } from './shared/services';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {
  userData:any=[]
  items:any=[];
  title = 'digimedix-ui';
  public href: string = "";
showHeader:boolean=false;
  constructor(private crudService: CrudService, private nbMenuService: NbMenuService,public sessionService: SessionService, private router: Router){

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  )
      .subscribe((event:any) => {
        if (event.url=='/login') {
          this.showHeader=false;
        } else {
          this.showHeader=true;
        }
          console.log(event);
      });
  }
  menuItem(){
    this.items=this.sessionService.getUserMenuItem();
  }
  ngOnInit(): void {
    this.menuItem();
    this.href = this.router.url;
            console.log(this.router.url);
    this.userData = this.sessionService.getUserItem('userInfo');
    this.nbMenuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'my-context-menu'),
      map(({ item: { title } }) => title),
    )
    .subscribe(title => {console.log('it',title);
  if (title=='Users') {
    this.router.navigate(['/users']);
  } else if(title=='Logout') {
    this.logOut();
  }

  });
  }
  users(){
    this.router.navigate(['/users']);
  }
  logOut() {
    this.sessionService.removeAllItem();
    this.router.navigate(['/']);
  }
}
