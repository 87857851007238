import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../services';
import { ModelService } from '../../services/model.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  id: any;
  homeID: any = this.sessionService.getItem('homeID');
  constructor(private sessionService: SessionService, private router: Router, public modelService: ModelService) { }

  ngOnInit(): void {

    this.id = this.sessionService.getItem('homeID')
  }


  logOut() {
    this.sessionService.removeAllItem();
    this.router.navigate(['/']);
  }
  File() {

    this.router.navigateByUrl(`/dashboard?id=${this.id}&name=Home`);
  }

}
