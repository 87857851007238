import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SessionService } from '../shared/services';
import { error } from 'console';
@Injectable({
  providedIn: 'root'
})
export class FolderService {
  baseURL: string = environment.API_URL;

  constructor(private sessionService: SessionService, private http: HttpClient) {
    this.sessionService.getItem('token');
  }

  CreateRootFolder(token: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.post<any>(this.baseURL + 'api/v3/createrootdirectory', {}, { headers })
      .pipe(map(res => {

        return res;
      }),
        catchError(error => throwError(error)));
  }

  GetHomeFolder(user_id: any): Observable<any> {
    console.log(this.sessionService.getItem('authToken')?.length);
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/gethomefolder', { "user_id": user_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }
  GetFolderDataAll(folder_id: any): Observable<any> {
    console.log(this.sessionService.getItem('authToken')?.length);
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/getfolderdataall', { "folder_id": folder_id, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }
  GetFolderData(folder_id: any): Observable<any> {
    console.log(this.sessionService.getItem('authToken')?.length);
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/getfolderdata', { "folder_id": folder_id, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  GetShareFolderData(folder_id: any): Observable<any> {
    return this.http.put<any>(this.baseURL + 'api/v3/getsharefolderdata', { "folder_id": folder_id, "target_folder_id": folder_id, 'shareSecret': '1kWY9UYyopV3rp0iV3c7Ow==' })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }
  GetTrashFolderData(user_id: any): Observable<any> {
    console.log(this.sessionService.getItem('authToken')?.length, user_id);
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/getrashfolderdata', { "user_id": user_id }, { headers })
      .pipe(map(res => {
        console.log('trash data', res);
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  CreateNewFolder(folder_id: any, folder_name: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.post<any>(this.baseURL + 'api/v3/createsubdirectory', { "folder_name": folder_name, "parent_folder_id": folder_id, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  MoveFolder(folder_id: any, new_folder_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/movedirectory', { "new_parent_folder_id": new_folder_id, "folder_id": folder_id, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  CopyFolder(folder_id: any, new_folder_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/copydirectory', { "new_parent_folder_id": new_folder_id, "folder_id": folder_id, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }


  MoveFolderToTrash(folder_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.post<any>(this.baseURL + 'api/v3/deletedirectory', { "folder_id": folder_id, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  RenameFolder(folder_id: any, folder_name: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/renamedirectory', { "folder_id": folder_id, "folder_name": folder_name, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  ArchiveFolder(folder_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/zipfolder', { "folder_id": folder_id, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }
  ArchiveMultipleFolder(folder_id: any, target_folder_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/zipmultiplefolder', { "folder_id": folder_id, "target_folder_id": target_folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  // GetTrashFolders(folder_id: any, target_folder_id: any): Observable<any> {
  //   var headers = new HttpHeaders({
  //     'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
  //   });
  //   return this.http.put<any>(this.baseURL + 'api/v3/getrashfolderdata', { "folder_id": folder_id, "target_folder_id": target_folder_id }, { headers })
  //     .pipe(map(res => {
  //       return res;
  //     }),
  //       catchError(error => throwError(error))
  //     );
  // }

  RestoreFolder(folder_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/restoredirectory', { "folder_id": folder_id, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  DeleteFolderFromTrash(folder_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.put<any>(this.baseURL + 'api/v3/permanentdeletedirectoryfromtrash', { "folder_id": folder_id, "target_folder_id": folder_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  CreateReadPermission(folder_id: any, user_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.post<any>(this.baseURL + 'api/v5/createfolderreadpermission', { "folder_id": folder_id, "new_user_id": user_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }

  CreateWritePermission(folder_id: any, user_id: any): Observable<any> {
    var headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.sessionService.getItem('authToken')
    });
    return this.http.post<any>(this.baseURL + 'api/v5/createfolderwritepermission', { "folder_id": folder_id, "new_user_id": user_id }, { headers })
      .pipe(map(res => {
        return res;
      }),
        catchError(error => throwError(error))
      );
  }
}
