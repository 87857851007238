import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { S3 } from 'aws-sdk';
import { environment } from 'src/environments/environment';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from '../../enums';
import { CrudService, RxjsService, SessionService, SnackbarService } from '../../services';
import { IApplicationResponse } from '../../utils';
import { FolderService } from 'src/app/services/folder.service';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-modal-delete-folder',
  templateUrl: './modal-delete-folder.component.html',
  styleUrls: ['./modal-delete-folder.component.scss']
})
export class ModalDeleteFolderComponent implements OnInit {
  folderName: any
  loading: boolean = false
  val: any;
  actionName: any;
  selectedFILE: any;
  isFolder: any;
  isMulti: boolean = false;
  constructor(private rxjsService: RxjsService, private crudService: CrudService, private snackbarService: SnackbarService,
    private sessionService: SessionService, private fileService: FileService, private toastr: ToastrService, private folderService: FolderService, private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  showSuccess(msg) {
    this.toastr.success('', msg);
  }

  showError(msg: any) {
    this.toastr.error('', msg);
  }
  ngOnInit(): void {
    console.log('delete path', this.data);
    this.actionName = 'Deleted';
    this.selectedFILE = this.data?.data;

    if (this.selectedFILE?.length > 0) {
      this.isFolder = this.selectedFILE[0].folder_id == undefined ? 'File' : 'Folder';
      this.isMulti = true;
    } else {
      this.isFolder = this.selectedFILE[0].folder_id == undefined ? 'File' : 'Folder';
      this.isMulti = false;
    }
    console.log('seleted file', this.selectedFILE);
    //  this.listAllKeys();

  }

  onSubmit() {
    this.loading = true;
    // let filevale;
    // if (this.isMulti) {
    //   let ids: any = [];
    //   this.selectedFILE.forEach(element => {
    //     ids.push(element.id);
    //   });
    //   filevale = { "selected_files": ids }

    // } else {
    //   filevale = { "selected_files": [this.selectedFILE.id] }

    //  }
    // console.log('selected_files', filevale)

    // console.log('this.selectedFILE', this.selectedFILE)

    this.selectedFILE.forEach(element => {
      console.log(element);
      if (this.isFolder == 'Folder') {
        this.folderService.MoveFolderToTrash(element.folder_id).subscribe((response: any) => {
          this.loading = false;
          if (response.success) {
            // this.snackbarService.openSnackbar(this.isFolder +' '+ this.actionName + ' Successfully!', ResponseMessageTypes.SUCCESS)
            this.showSuccess(response.msg);
            this.rxjsService.setGlobalReloadProperty(true)
            this.dialogRef.close();
          } else {
            this.showError('Error in Deleting Folder');
            // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
          }
        }, error => {
          this.loading = false;
          this.showError(error.error);
          // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
        });
      } else {
        this.fileService.MoveFileToTrash(element.file_id).subscribe((response: any) => {
          this.loading = false;
          if (response.success) {
            // this.snackbarService.openSnackbar(this.isFolder +' '+ this.actionName + ' Successfully!', ResponseMessageTypes.SUCCESS)
            this.showSuccess(response.msg);
            this.rxjsService.setGlobalReloadProperty(true)
            this.dialogRef.close();
          } else {
            this.showError('Error in Deleting File');
            // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
          }
        }, error => {
          this.loading = false;
          this.showError(error.error);
          // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
        });
      }
    });

    // this.crudService.deleteFiles(ModulesBasedApiSuffix.FOLDER_DELETE, filevale).subscribe((response: any) => {
    //   this.loading = false;
    //   if (response.success) {
    //     // this.snackbarService.openSnackbar(this.isFolder +' '+ this.actionName + ' Successfully!', ResponseMessageTypes.SUCCESS)

    //     this.rxjsService.setGlobalReloadProperty(true)
    //     this.dialogRef.close();
    //   } else {
    //     // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
    //   }
    // }, error => {
    //   this.loading = false;
    //   // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
    // });
  }


}
