<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<mat-dialog-content class="mat-typography-title">
    <h2 mat-dialog-title>Restore Files / Folders :
    </h2>
    <mat-icon mat-dialog-close style="cursor: pointer">close</mat-icon>
</mat-dialog-content>
<div class="notification-wrapper">
    <div class="col-10" *ngIf="dashBoardItems?.length > 0"
        style="width: 100%;height: 420px;  overflow-y: auto; background-color: white;">
        <div class="row files-wrapper" *ngFor="let item of dashBoardItems; let i=index">
            <div class="col-xs-6">
                <div class="files-detail">
                    <mat-checkbox class="example-margin" name="{{i}}-key" [checked]="item.checked"
                        (change)="onSelected(item,$event)">
                        <img src="/assets/images/folder.png" *ngIf="item.isFolder">
                        <img src="/assets/images/img.png" *ngIf="item.extention == 'img'">
                        <img src="/assets/images/mp3.png" *ngIf="item.extention == 'mp3'">
                        <img src="/assets/images/mp4.png" *ngIf="item.extention == 'mp4'">
                        <img src="/assets/images/doc.png" *ngIf="item.extention == 'doc'">
                        <img src="/assets/images/txt.png" *ngIf="item.extention == 'txt'">
                        <img src="/assets/images/xls.png" *ngIf="item.extention == 'xls'">
                        <img src="/assets/images/ppt.png" *ngIf="item.extention == 'ppt'">
                        <img src="/assets/images/zip.png" *ngIf="item.extention == 'zip'">
                        <img src="/assets/images/pdf.png" *ngIf="item.extention == 'pdf'">
                        <img src="/assets/images/iso.png" *ngIf="item.extention == 'iso'">
                        <img src="/assets/images/svg.png" *ngIf="item.extention == 'svg'">
                        <img src="/assets/images/docs.png" *ngIf="item.extention == 'docs'">
                    </mat-checkbox>
                    <a style="margin-left: 20px;" *ngIf="item.type== 'folder'">
                        <span>
                            <h6> {{ item?.folder_name}}</h6>
                            <p style="margin-top: 0.6rem;">Created {{item?.created_at | date:'dd/MM/YYY h:m a'}}</p>
                        </span>
                    </a>
                    <span style="margin-left: 20px;" *ngIf="item.type == 'file'" style="cursor:default">
                        <span>
                            <h6>{{ item?.file_name}}</h6>
                            <p style="margin-top: 0.6rem;">Created {{item?.created_at | date:'dd/MM/YYY h:m a'}}</p>
                        </span>
                    </span>
                </div>

            </div>

        </div>
    </div>
</div>
<!-- <p-tree [value]="items" selectionMode="single" [(selection)]="selectedItem"
        (onNodeSelect)="onNodeSelect($event)"></p-tree>
    <div class="col-12" style="margin-top: 15px;">
        <mat-form-field rm-field class="example-full-width" appearance="fill">
            <mat-label>Telephone</mat-label>
            <span matPrefix>Path: &nbsp;</span>
            <input type="tel" matInput formControlName="targetFilePath">
            <mat-icon matSuffix>mode_edit</mat-icon>
        </mat-form-field>
    </div> -->
<mat-dialog-content class="mat-typography">


</mat-dialog-content>
<br />
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button class="start-btn" color="primary" mat-button cdkFocusInitial (click)="onRestore()">Restore</button>
    <button class="start-btn" color="primary" mat-button cdkFocusInitial (click)="onDelete()">Delete
        Permanently</button>
</mat-dialog-actions>