import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/services/file.service';
import { FolderService } from 'src/app/services/folder.service';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from 'src/app/shared/enums';
import { CrudService, RxjsService, SessionService, SnackbarService } from 'src/app/shared/services';
import { IApplicationResponse } from 'src/app/shared/utils';

@Component({
  selector: 'app-modal-archive-folder',
  templateUrl: './modal-archive-folder.component.html',
  styleUrls: ['./modal-archive-folder.component.scss']
})
export class ModalArchiveFolderComponent implements OnInit {

  loading: boolean = false
  createFolderForm: FormGroup;
  isType: any;
  isMulti: any;
  selectedFILE: any;
  actionName: any;
  constructor(private formBulder: FormBuilder, private rxjsService: RxjsService, private crudService: CrudService, private snackbarService: SnackbarService,
    private sessionService: SessionService, private fileService: FileService, private folderService: FolderService, private toastr: ToastrService, private router: Router, private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createFolderForm = this.formBulder.group({
      folderName: ['', Validators.required],
      currentpath: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    console.log('delete path', this.data);
    this.actionName = this.data ? this.data?.actionName : this.data?.actionName;
    this.selectedFILE = this.data.data;
    this.isType = this.selectedFILE[0].folder_id == undefined ? 'File' : 'Folder';
    this.isMulti = this.selectedFILE.length > 1 ? true : false;
    // this.actionName = 'Deleted';
    // this.selectedFILE = this.data?.data;
  }
  showSuccess(msg) {
    this.toastr.success('', msg);
  }
  showError(msg: any) {
    this.toastr.error('', msg);
  }
  onSubmit() {
    let pathid = this.sessionService.getItem('curentFullPathArray') ? this.sessionService.getItem('curentFullPathArray') : 0;

    this.loading = true;
    let userData = this.sessionService.getUserItem('userData')
    this.createFolderForm.get('currentpath')?.setValue('/' + userData?.directory_info[0]?.baseDirectory + '/')
    console.log(userData, this.createFolderForm.value, pathid, this.isType, this.selectedFILE);
    if (this.createFolderForm.invalid) {
      this.loading = false;
      return;
    }
    if (this.isMulti) {
      var ids: any = [];
      this.selectedFILE.forEach(element => {
        ids.push(element.folder_id);
      });
      this.folderService.ArchiveMultipleFolder(ids, this.data[0].parent_id)
        .subscribe((response) => {
          this.loading = true;
          if (response.success) {
            // this.snackbarService.openSnackbar('Folder Download Successfully!', ResponseMessageTypes.SUCCESS)
            console.log(response);
            if (this.actionName == 'Download') {
              var blob = new Blob([new Uint8Array(response.buffer.data)], { type: 'application/zip' });
              const blobURL = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.download = this.createFolderForm.value.folderName + '.zip'; // Set the file name
              anchor.href = blobURL;
              anchor.click();
              window.URL.revokeObjectURL(blobURL);
            } else {
              this.fileService.CreateFile(this.data[0].parent_id, response.buffer, this.createFolderForm.value.folderName).subscribe((res) => {
                this.rxjsService.setGlobalReloadProperty(true)
                this.showSuccess('Archived Successfully');
                this.dialogRef.close();
              }, error => {
                this.loading = false;
                this.showError(error.error);
                // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
              })
            }

          } else {
            this.loading = false;
            this.showError('Error Archive Folder');
            // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
          }
        }, error => {
          this.loading = false;
          this.showError(error.error);
          // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
        });
    }
    else {
      if (this.isType == 'Folder') {
        this.folderService.ArchiveFolder(this.selectedFILE[0].folder_id)
          .subscribe((response) => {
            this.loading = true;
            if (response.success) {
              // this.snackbarService.openSnackbar('Folder Download Successfully!', ResponseMessageTypes.SUCCESS)
              console.log(response);
              if (this.actionName == 'Download') {
                var blob = new Blob([new Uint8Array(response.buffer.data)], { type: 'application/zip' });
                const blobURL = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = this.createFolderForm.value.folderName + '.zip'; // Set the file name
                anchor.href = blobURL;
                anchor.click();
                window.URL.revokeObjectURL(blobURL);
                this.showSuccess('Download Successfully');
                this.dialogRef.close();
              } else {
                this.fileService.CreateFile(this.data[0].parent_id, response.buffer, this.createFolderForm.value.folderName).subscribe((res) => {
                  this.rxjsService.setGlobalReloadProperty(true)
                  this.showSuccess('Archived Successfully');
                  this.dialogRef.close();
                }, error => {
                  this.loading = false;
                  this.showError(error.error);
                  // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
                })
              }

            } else {
              this.loading = false;
              this.showError('Error Archive Folder');
              // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
            }
          }, error => {
            this.loading = false;
            this.showError(error.error);
            // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
          });
      } else {
        this.fileService.ArchiveFile(this.selectedFILE[0].file_id)
          .subscribe((response) => {
            this.loading = true;
            if (response.success) {
              // this.snackbarService.openSnackbar('Folder Download Successfully!', ResponseMessageTypes.SUCCESS)
              console.log(response);
              if (this.actionName == 'Download') {
                var blob = new Blob([new Uint8Array(response.buffer.data)], { type: 'application/zip' });
                const blobURL = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = this.createFolderForm.value.folderName + '.zip'; // Set the file name
                anchor.href = blobURL;
                anchor.click();
                window.URL.revokeObjectURL(blobURL);
                this.showSuccess('Download Successfully');
                this.dialogRef.close();
              } else {
                this.fileService.CreateFile(this.data[0].parent_id, response.buffer, this.createFolderForm.value.folderName).subscribe((res) => {
                  this.rxjsService.setGlobalReloadProperty(true)
                  this.showSuccess('Archived Successfully');
                  this.dialogRef.close();
                }, error => {
                  this.loading = false;
                  this.showError(error.error);
                  // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
                })
              }

            } else {
              this.loading = false;
              this.showError('Error Archive Folder');
              // this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
            }
          }, error => {
            this.loading = false;
            this.showError(error.error);
            // this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
          });
      }
    }

  }


}

