import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from 'src/app/shared/enums';
import { CrudService, RxjsService, SessionService, SnackbarService } from 'src/app/shared/services';
import { IApplicationResponse } from 'src/app/shared/utils';
import { TreeNode } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';
import { FileService } from 'src/app/services/file.service';
import { FolderService } from 'src/app/services/folder.service';
//import { RemoveSlashPipe } from '../../pipes/remove-slash.pipe';
@Component({
  selector: 'app-get-tree-view',
  templateUrl: './get-tree-view.component.html',
  styleUrls: ['./get-tree-view.component.scss']
})
export class GetTreeViewComponent implements OnInit {
  items: TreeNode[] = [];
  //items: any = [];
  selectedItem!: TreeNode;
  //selectedItem:any=[];
  loading: boolean = false
  moveFolderForm: FormGroup;
  pathList: any = [];
  nodeParent: string = '';
  fullpathlist: any = [];
  selectedModulePermissions: any = []
  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any, private formBulder: FormBuilder, private crudService: CrudService, private sessionService: SessionService, private fileService: FileService, private folderService: FolderService) {
    this.moveFolderForm = this.formBulder.group({
      filename: ['', Validators.required],
      currentFilePath: ['', Validators.required],
      targetFilePath: [''],
    })

  }
  private getS3Bucket(): any {
    let userData = this.sessionService.getUserItem('userData')
    const bucket = new S3(
      {

        accessKeyId: environment.S3_ACCESS_KEY,
        secretAccessKey: environment.S3_SECRET_KEY,
        region: userData?.storage_Server?.Region,
      }
    );
    var params = {
      Bucket: userData?.directory_info[0]?.bucket_name,
      Delimiter: '/',
      Prefix: 'test/',
    }
    bucket.listObjects(params, function (err, data) {
      if (err) throw err;
      console.log(data);
    });

  }
  ngOnInit() {
    this.gettreeview_new()
  }
  gettreeview_new() {
    let folderid = this.sessionService.getItem('homeID');
    console.log('getree', folderid)

    this.folderService.GetFolderDataAll(folderid)
      .pipe(map((res: any) => {
        console.log('res  1', res)
        // res?.data?.children.forEach((element: any) => {
        //   element.type = element.type;
        //   element.isFolder = element.folder_name.includes('.') ? false : true;
        //   element.name = element.folder_name;
        //   element.id = element.folder_id;
        //   //   element.filePath =  userData?.directory_info[0]?.baseDirectory + '/' + element.Key
        //   //element.fileName = this.removeSlashPipe.transform(element.Key)

        // });
        console.log(res);
        return res
      }))
      .subscribe((response: any) => {
        response?.data.children.forEach((element: any) => {
          console.log(element);
          // this.items.push({ label: element.folder_name.toString(), folder_id: element.folder_id, expandedIcon: "pi pi-folder-open", collapsedIcon: "pi pi-folder", children: [{ label: "hello", folder_id: element.folder_id, expandedIcon: "pi pi-folder-open", collapsedIcon: "pi pi-folder" }] });
          this.items.push(element);
          console.log('restree', this.items)

        });
        var dat = {
          label: 'root:/', expandedIcon: "pi pi-folder-open", collapsedIcon: "pi pi-folder",
          folder_id: this.sessionService.getItem('homeID')
        };
        this.items.push(dat);
        this.items.reverse();
      },
        //


        error => {
          this.loading = false;
        });
  }
  onNodeSelect(event: any) {


    // if (event.node) {
    //   this.pathList = [];
    //   this.pathList.push(event?.node?.label);
    //   this.fullpathlist.push(event?.node?.filepath);
    // }
    // console.log('event', event.node)
    // console.log('fullpathlist', this.fullpathlist)
    var itm = { label: "Tesfda" };
    if (event.node) {




      this.moveFolderForm.get('targetFilePath')?.setValue(event?.node?.label)
      this.moveFolderForm.get('folder_id')?.setValue(event?.node?.folder_id)


      console.log('event', event.node)
    }

  }
  ngOnInit1(): void {
    this.getS3Bucket();

    this.gettreeViewData('', '');
  }
  // private loadTree(nodeId:string| undefined=undefined){
  //  // this.loading=true;

  // }


  onNodeSelect1(event: any) {
    console.log('event', event)
    if (event?.node?.parent?.label) {
      this.nodeParent = event?.node?.parent?.label;
    }
    this.gettreeViewData(event?.node?.label, this.nodeParent);
    this.pathList.push(event?.node?.label);
    // this.moveFolderForm.get('targetFilePath')?.setValue(this.pathList.toString());
    console.log('moveFolderForm', this.moveFolderForm, this.pathList)
    //   this.moveFolderForm.get('targetFilePath')?.setValue(this.moveFolderForm.get('targetFilePath')?.value?this.moveFolderForm.get('targetFilePath')?.value+', '+ event?.node?.label : event?.node?.label)
  }
  nodeUnselect(event: any) {
    const index = this.pathList.indexOf(event?.node?.label);
    console.log(index); // 👉️ 1

    if (index !== -1) {
      this.pathList.splice(index, 1);
      this.fullpathlist.splice(index, 1);
    }
    console.log('node unslected', this.selectedItem);
    //this.pathList.filter((item:string) => item.toString() !== event?.node?.label.toString());
    //this.moveFolderForm.get('targetFilePath')?.setValue(this.pathList.toString())
    console.log('pathlist', this.pathList);
    //this.messageService.add({severity: 'info', summary: 'Node Unselected', detail: event.node.label});
  }
  gettreeViewData(subfolder: string, nodeParent: string) {
    this.loading = true;
    let userData = this.sessionService.getUserItem('userData')
    subfolder = subfolder ? subfolder + '/' : '';
    nodeParent = nodeParent ? nodeParent + '/' : '';
    let requestParams = new HttpParams().set('baseDirectory', '/' + userData?.directory_info[0]?.baseDirectory + '/' + nodeParent + subfolder);
    //let requestParams = new HttpParams().set('baseDirectory', '/' +'ul2hfi5vem3b6qw/numberone/numbertwo/numberthree/');
    console.log('requestParams', requestParams)
    this.crudService.get(ModulesBasedApiSuffix.GETTREEVIEW, null, requestParams)
      .pipe(map((res: any) => {
        console.log('res  1', res)
        res?.data?.forEach((element: any) => {
          element.type = element.Key.includes('.') ? 'file' : 'folder'
          element.isFolder = element.Key.includes('.') ? false : true
          element.filePath = userData?.directory_info[0]?.baseDirectory + '/' + element.Key
          //element.fileName = this.removeSlashPipe.transform(element.Key)
          if (element.Key.includes('.')) {
            element.extention = element.Key.split('.')[element.Key.split('.').length - 1]

          } else {
            let curentpath = element.Key.split('/');
            console.log('curent path -2', curentpath[curentpath.length - 2])
            element.Key = curentpath[curentpath.length - 2] + '/';
            element.extention = null
          }
        });

        return res
      }))
      .subscribe((response: any) => {
        response?.data.forEach((element: any) => {
          //    this.items.push({ label: element.Key.toString().slice(0, -1), HIERARCHY_LEVEL: 1 ,filepath:'/'+element.filePath,expandedIcon: "pi pi-folder-open",collapsedIcon: "pi pi-folder"});

        });

        //  this.dashBoardItems = response?.data;
        //       if (response?.data) {


        //       response?.data.forEach((element: any) => {

        //         element.label = element.Key,
        //             element.data = element.Key,
        //             element.expandedIcon = "pi pi-folder-open",
        //             element.collapsedIcon = "pi pi-folder"
        //           element.children = []
        //         })
        //         if (subfolder!='') {
        //           let objIndex;
        //           let parentIndex;

        //           if (nodeParent!='') {

        // parentIndex=this.items.findIndex((obj => obj.label == nodeParent));
        // console.log("parent arr: ",this.items[parentIndex]?.children)

        //   objIndex = this.items[parentIndex]?.children?.findIndex((obj => obj.label == subfolder));
        //   console.log("object index",objIndex)
        //   let da:any=[];
        //   da=this.items[parentIndex].children;
        //   console.log('da',da)
        // da=  response?.data;
        // this.items[parentIndex].children=da;
        // console.log('after da',da)
        // console.log("parent update: ", this.items[objIndex?objIndex:0])
        //           } else {
        // objIndex = this.items.findIndex((obj => obj.label == subfolder));
        // this.items[objIndex].children =  response?.data;
        // console.log("child update: ", this.items[objIndex?objIndex:0])
        //           }




        //        //Find index of specific object using findIndex method.
        //   //     if (this.items[objIndex]?.children?.length!=0) {

        //     //   } else {
        //         //Log object to Console.
        // //console.log("Before update: ", this.items[objIndex])

        // //Update object's name property.

        //         //  this.items=this.items.map(el =>( el.label === subfolder ? {...el , el.children : response.data} : el))
        //         //console.log("after update: ", this.items[objIndex])
        //       // }



        //         } else {
        //           this.items = response?.data;
        //         }

        //         console.log('items',this.items,'res',response)

        //       }
        //this.items = response?.data;

        console.log('subfolder', subfolder, 'paren', nodeParent, 'respon daata', response?.data);
        let subfolderdata: any = [];
        if (subfolder) {
          subfolderdata = response?.data.filter((val: any) => val.Key !== subfolder);
          console.log('subfolderdata', subfolderdata);
          let fullpath = subfolderdata[0].filePath.split('/').filter((x: any) => x != '');
          fullpath.shift();
          console.log('fullpath', fullpath);
          let bindtwo: any = [];
          fullpath.forEach((element: any, index: any) => {

            bindtwo.push({ label: element, HIERARCHY_LEVEL: index + 1, expandedIcon: "pi pi-folder-open", collapsedIcon: "pi pi-folder", partialSelected: false })
          });
          console.log('before selectedItem', this.selectedItem);
          let beforeselected = this.selectedItem;
          console.log('bindtwo', bindtwo);
          var x = this.getTree(bindtwo);
          console.log('bindtwo x', x);
          let getParentFolder = nodeParent ? nodeParent : subfolder;

          const index = this.items.findIndex((el: any) => el.label === getParentFolder?.toString().slice(0, -1));
          console.log('index', index);
          console.log('sub or parent', getParentFolder)
          this.items[index].children = x[0].children;
          //this.selectedItem=[];
          //this.selectedItem.push(x[0]);
          //this.selectedItem=beforeselected;
          console.log('getParentFolder', getParentFolder);
          console.log('after selectedItem', this.selectedItem);




          // let parentonly=this.items.filter((x:any)=>x.label==getParentFolder?.toString().slice(0, -1));
          // let fresh:any= [];
          // let oldDBpath=[{label: fullpath[fullpath.length-1], HIERARCHY_LEVEL: fullpath.length ,expandedIcon: "pi pi-folder-open",collapsedIcon: "pi pi-folder"}];
          // console.log('parent ony',parentonly);
          // console.log('olddbpath', oldDBpath)
          // parentonly.forEach((p:any)=>{
          //   fresh.push(p);
          //   oldDBpath.forEach((c:any) => {
          //   //  if (c.Parent == p.label) {
          //      fresh.push(c)
          //     //}
          //   });
          // });
          // console.log('fresh',fresh)
          // var x=this.getTree(fresh);
          // console.log('gettree', x);

          // console.log('after selectedItem',this.selectedItem);
        } else {
          response?.data.forEach((element: any) => {
            ///  this.items.push({ label: element.Key.toString().slice(0, -1), HIERARCHY_LEVEL: 1 ,filepath:'/'+element.filePath,expandedIcon: "pi pi-folder-open",collapsedIcon: "pi pi-folder"});

          });
        }
        //
        console.log('dash item', this.items);


        this.loading = false;

      }, error => {
        this.loading = false;
      });
  }
  async gettreeViewDataExpand(subfolder: string, nodeParent: string) {

  }
  getTree(array: any) {
    var levels: any = [{}];
    array.forEach((o: any) => {
      levels.length = o.HIERARCHY_LEVEL;
      levels[o.HIERARCHY_LEVEL - 1].children = levels[o.HIERARCHY_LEVEL - 1].children || [];
      levels[o.HIERARCHY_LEVEL - 1].children.push(o);
      levels[o.HIERARCHY_LEVEL] = o;
    });
    return levels[0].children;
  }
  onSubmit() {
    console.log('sele', this.selectedItem)

    //this.moveFolderForm.get('targetFilePath')?.setValue(this.selectedItem?.filepath)
    console.log('full path list submit', this.fullpathlist)
    this.dialogRef.close(this.selectedItem);
  }


  async loadNode(event: any) {

    if (event.node) {
      //in a real application, make a call to a remote url to load children of the current node and add the new nodes as children
      console.log('event', event);
      let subfolder = event?.node?.label;
      let nodeParent = event?.node?.parent?.label;
      //let res=  await  this.gettreeViewDataExpand(,)
      let userData = this.sessionService.getUserItem('userData')
      subfolder = subfolder ? subfolder + '/' : '';
      nodeParent = nodeParent ? nodeParent + '/' : '';
      let requestParams = new HttpParams().set('baseDirectory', '/' + userData?.directory_info[0]?.baseDirectory + '/' + nodeParent + subfolder);
      //let requestParams = new HttpParams().set('baseDirectory', '/' +'ul2hfi5vem3b6qw/numberone/numbertwo/numberthree/');
      console.log('requestParams', requestParams)
      this.crudService.get(ModulesBasedApiSuffix.GETTREEVIEW, null, requestParams)
        .pipe(map((res: any) => {
          console.log('res  1', res)
          res?.data?.forEach((element: any) => {
            element.type = element.Key.includes('.') ? 'file' : 'folder'
            element.isFolder = element.Key.includes('.') ? false : true
            element.filePath = userData?.directory_info[0]?.baseDirectory + '/' + element.Key
            //element.fileName = this.removeSlashPipe.transform(element.Key)
            if (element.Key.includes('.')) {
              element.extention = element.Key.split('.')[element.Key.split('.').length - 1]

            } else {
              let curentpath = element.Key.split('/');
              console.log('curent path -2', curentpath[curentpath.length - 2])
              element.Key = curentpath[curentpath.length - 2] + '/';
              element.extention = null
            }
          });

          return res
        }))
        .subscribe((response: any) => {


          console.log('subfolder', subfolder, 'paren', nodeParent, 'respon daata', response?.data);
          let subfolderdata: any = [];
          if (subfolder && response?.data) {
            subfolderdata = response?.data.filter((val: any) => val.Key !== subfolder);
            console.log('subfolderdata', subfolderdata);
            let fullpath = subfolderdata[0].filePath.split('/').filter((x: any) => x != '');
            fullpath.shift();
            console.log('fullpath', fullpath);
            let bindtwo: any = [];
            fullpath.forEach((element: any, index: any) => {

              bindtwo.push({ label: element, HIERARCHY_LEVEL: index + 1, expandedIcon: "pi pi-folder-open", collapsedIcon: "pi pi-folder", partialSelected: false })
            });
            console.log('before selectedItem', this.selectedItem);
            let beforeselected = this.selectedItem;
            console.log('bindtwo', bindtwo);
            console.log('after selectedItem', this.selectedItem);

            this.loading = false;
            event.node.children = [bindtwo[bindtwo.length - 1]];
          }
          console.log('dash item', this.items);




        }, error => {
          this.loading = false;
        });
      //   this.nodeService.getLazyFiles().then(nodes => event.node.children = nodes);
    }
  }
  onNodeSelect2(event: any) {


    if (event.node) {
      this.pathList = [];
      this.pathList.push(event?.node?.label);
      this.fullpathlist.push(event?.node?.filepath);

      //old multithis.moveFolderForm.get('targetFilePath')?.setValue(this.pathList.toString())
      //this.moveFolderForm.get('targetFilePath')?.setValue(this.pathList.toString())
      console.log('moveFolderForm', this.moveFolderForm, this.pathList)
      return;
      this.loading = true;
      //in a real application, make a call to a remote url to load children of the current node and add the new nodes as children
      console.log('event', event);
      console.log('node slected', this.selectedItem);
      let subfolder = event?.node?.label;
      let nodeParent = event?.node?.parent?.label;
      let userData = this.sessionService.getUserItem('userData');
      let reqarams = event?.node?.filepath ? event?.node?.filepath : '/' + userData?.directory_info[0]?.baseDirectory + '/';
      //let res=  await  this.gettreeViewDataExpand(,)

      subfolder = subfolder ? subfolder + '/' : '';
      nodeParent = nodeParent ? nodeParent + '/' : '';
      let requestParams = new HttpParams().set('baseDirectory', reqarams);//userData?.directory_info[0]?.baseDirectory + '/'+nodeParent+subfolder
      //let requestParams = new HttpParams().set('baseDirectory', '/' +'ul2hfi5vem3b6qw/numberone/numbertwo/numberthree/');
      console.log('requestParams', requestParams)
      this.crudService.get(ModulesBasedApiSuffix.GETTREEVIEW, null, requestParams)
        .pipe(map((res: any) => {
          console.log('res  1', res)
          res?.data?.forEach((element: any) => {
            element.type = element.Key.includes('.') ? 'file' : 'folder'
            element.isFolder = element.Key.includes('.') ? false : true
            element.filePath = userData?.directory_info[0]?.baseDirectory + '/' + element.Key
            //element.fileName = this.removeSlashPipe.transform(element.Key)
            if (element.Key.includes('.')) {
              element.extention = element.Key.split('.')[element.Key.split('.').length - 1]

            } else {
              let curentpath = element.Key.split('/');
              console.log('curent path -2', curentpath[curentpath.length - 2])
              element.Key = curentpath[curentpath.length - 2] + '/';
              element.extention = null
            }
          });

          return res
        }))
        .subscribe((response: any) => {


          console.log('subfolder', subfolder, 'paren', nodeParent, 'respon daata', response?.data);
          let subfolderdata: any = [];
          if (subfolder && response?.data) {
            subfolderdata = response?.data.filter((val: any) => val.Key !== subfolder);
            console.log('subfolderdata', subfolderdata);
            let bindtwo: any = [];
            for (let index = 0; index < subfolderdata.length; index++) {
              let fullpath = subfolderdata[index].filePath.split('/').filter((x: any) => x != '');
              fullpath.shift();
              console.log('fullpath', fullpath);//['numberone', 'numbertwo', 'movie']

              //fullpath.forEach((element:any,index:any) => {

              bindtwo.push({ label: fullpath[fullpath.length - 1], HIERARCHY_LEVEL: fullpath.length, filepath: '/' + subfolderdata[index].filePath, expandedIcon: "pi pi-folder-open", collapsedIcon: "pi pi-folder", partialSelected: false })
              //});
              console.log('before selectedItem', this.selectedItem);
              let beforeselected = this.selectedItem;
              console.log('bindtwo', bindtwo);
              console.log('after selectedItem', this.selectedItem);

            }
            event.node.children = bindtwo;
            //this.selectedItem.push(bindtwo[bindtwo.length-1]);
          }
          console.log('dash item', this.items);
          this.loading = false;



        }, error => {
          this.loading = false;
        });
      //   this.nodeService.getLazyFiles().then(nodes => event.node.children = nodes);
    }
  }
}
