import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModulesBasedApiSuffix, ResponseMessageTypes } from '../../enums';
import { CrudService, RxjsService, SessionService, SnackbarService } from '../../services';
import { IApplicationResponse, prepareGetRequestHttpParams } from '../../utils';

@Component({
  selector: 'app-modal-info-folder',
  templateUrl: './modal-info-folder.component.html',
  styleUrls: ['./modal-info-folder.component.scss']
})
export class ModalInfoFolderComponent implements OnInit {
  loading: boolean = false
  folderInfo: any;
  contentlen: any;
  modified: any;
  type: any;
  where: any = '---';
  isType: any;
  constructor(private rxjsService: RxjsService, private crudService: CrudService, private snackbarService: SnackbarService,
    private sessionService: SessionService, private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.getInfo()
  }

  getInfo() {
    this.loading = false;
    console.log('getinfo ', this.data)
    let type;
    if (this.data?.isFolder) {
      type = "folder/getinfo/"
    } else {
      type = 'file/getinfo/'
    }
    let otherParams: any = this.data?.id;

    if (this.data?.file_name.includes('.')) {
      this.isType = 'File';
    } else {
      this.isType = 'Folder';
    }
    if (this.isType == 'File') {
      this.data.file_size = (this.data.file_size / 1024).toFixed();
      const lastDotIndex = this.data.file_name.lastIndexOf(".");
      const name = this.data.file_name.slice(0, lastDotIndex);
      const extension = this.data.file_name.slice(lastDotIndex + 1);
      this.data.type2 = extension
    }
    // console.log('otherParams', otherParams)
    // this.crudService.GetfileInfo(type, otherParams)
    //   .subscribe((response: any) => {
    //     this.loading = false;
    //     console.log('res', response)
    //     if (response) {
    //       this.folderInfo = response;
    //       var valuesArray = Object.values(this.folderInfo);

    //       for (let value of valuesArray) {
    //         this.folderInfo = value;
    //       }
    //       console.log('current', localStorage.getItem('curentFullPath'))

    //       if (!this.sessionService.getItem('curentFullPath')) {
    //         this.where = '/';
    //       }
    //       else {
    //         this.where = this.sessionService.getItem('curentFullPath');
    //       }

    //       this.contentlen = this.folderInfo?.headers?.['content-length'];
    //       this.modified = this.folderInfo?.headers?.['last-modified'];
    //       this.type = this.folderInfo?.headers?.['content-type'];
    //     } else {
    //       this.snackbarService.openSnackbar(response.msg, ResponseMessageTypes.WARNING)
    //     }
    //   }, (error: any) => {
    //     this.loading = false;
    //     this.snackbarService.openSnackbar(error.error.message, ResponseMessageTypes.WARNING)
    //   });
  }


}
