import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  // private userSubject: BehaviorSubject<any>;
  // public userPermission: Observable<any>;
  constructor() {
    // this.userSubject = new BehaviorSubject<any>(filtered);
    // this.userPermission = this.userSubject.asObservable();

  }
  public get getuserPermission(): any {
    let userpermision=this.getUserItem('userData')?.permissions;
//console.log('ppp',userpermision);
let acces;var filtered;
if (userpermision) {
  var keys = Object?.keys(userpermision);

 filtered = keys?.filter(function(key) {
    return userpermision[key]
});


}
//console.log('acce',filtered)

    return filtered;
}
  isTokenValid(): boolean {
    return sessionStorage.getItem('token') ? true : false;
  }

  setItem(item: any, value: any) {
    return sessionStorage.setItem(item, value)
  }

  getItem(item: any) {
    return sessionStorage.getItem(item)
  }

  setUserItem(item: any, value: any) {
    return sessionStorage.setItem(item, JSON.stringify(value))
  }

  getUserItem(item: any) {
    let data: any = sessionStorage.getItem(item)
    return JSON.parse(data)
  }
  getUserMenuItem() {
    let usr=this.getUserItem('userInfo');
    console.log('user',usr)
let arr:any=[];
    if (usr?.role_id==2) {
      arr= [
        { title: 'Users' },
        { title: 'Logout' },
      ];
    }else{
      arr= [
        { title: 'Logout' },
      ];
    }
    return arr;
  }
  removeItem(item: any) {
    sessionStorage.removeItem(item)
  }

  removeAllItem() {
    sessionStorage.clear()
  }

}
